.truncateCompanyName {
  text-transform: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 130px;
  margin-right: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: block;
  overflow: hidden;
}

@media (width <= 1024px) {
  .truncateCompanyName {
    display: none;
  }
}

.iconContainer {
  all: initial;
  position: relative;
}

@media (width <= 1340px) {
  .hidden {
    display: none;
  }
}
