.spinner {
  animation: spin 2s infinite linear;
  width: 100%;
  height: 100%;
}

.shadow {
  fill: none;
  stroke-width: 37px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.small {
  width: 40px;
  height: 40px;
}

.tiny {
  width: 16px;
  height: 16px;
}

.colorLightSolid {
  fill: #fff;
  stop-color: #fff;
}

.colorLightTransparent {
  fill: #fff;
  stop-color: #fff;
  stop-opacity: 0;
}

.colorDarkShadow {
  stroke: #ddd;
}

.colorLightShadow {
  stroke: transparent;
}

.colorBlackSolid {
  fill: #222;
  stop-color: #222;
}

.colorDarkSolid {
  fill: #559b5e;
  stop-color: #559b5e;
}

.colorDarkTransparent {
  fill: #559b5e;
  stop-color: #559b5e;
  stop-opacity: 0;
}