:local .stopModalScroll {
  overflow-y: hidden;
}
:local .spinnerWrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
}
@media (min-width: 769px) {
  :local .spinnerWrapper {
    min-height: 250px;
  }
}
@media (max-width: 768px) {
  :local .spinnerWrapper {
    z-index: 10;
  }
}