:local .button {
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  box-shadow: none;
  box-sizing: border-box;
  border: 0;
  border-radius: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  text-decoration: none;
  transition: all .175s linear;
  display: inline-block;
  position: relative;
}

:local .button:focus-visible {
  outline: none;
}

:local .button:focus-visible:after {
  z-index: 10030;
  content: "";
  border-radius: 2px;
  position: absolute;
  inset: -1px;
  box-shadow: 0 0 2px 2px #2a7140;
}

:local .button:global(.focus-visible) {
  outline: none;
}

:local .button:global(.focus-visible):after {
  z-index: 10030;
  content: "";
  border-radius: 2px;
  position: absolute;
  inset: -1px;
  box-shadow: 0 0 2px 2px #2a7140;
}

:local .button.disabled {
  color: #fff;
  cursor: not-allowed;
  background-color: #ddd;
  border: 0;
}

:local .primaryButton {
  color: #fff;
  background-color: #559b5e;
}

:local .primaryButton:hover:not(.disabled) {
  color: #fff;
  background-color: #2a7140;
}
