:local .title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  color: #222;
  position: relative;
  margin-bottom: 36px;
}
:local .title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -18px;
  width: 60px;
  height: 2px;
  margin: 18px auto 0;
  background-color: #c2a661;
}
:local .page {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
:local .footer {
  padding-top: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}