.icon {
  width: 20px;
  height: 20px;
  margin-right: 9px;
}

@media (width <= 1120px) {
  .icon .svgIcon {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
}

@media (width >= 1121px) {
  .icon {
    margin: 0 0 9px;
    padding: 0;
    position: relative;
  }
}

.svgIcon {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 3px;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
