.list {
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  margin: 0;
  padding: 0 36px 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  list-style: none;
  transition: opacity .1s;
  display: block;
  position: relative;
}

.container {
  margin: 0;
  transition: height .2s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}
