:global {
  /*
  * Extends bootstrap. Overwrites some bootstrap styling and adds some
  * additional classes. Intended to adapt bootstrap to the dealer
  * admin style guide.
  */
  /* NAVIGATION */
  /* OVERRIDE STYLES FROM DATERANGE-PICKER PLUGIN */
}
:global .nav-pills > .active > a,
:global .nav-pills > .active > a:hover,
:global .nav-pills > .active > a:focus {
  color: #fff;
  background-color: #436b93;
}
:global .nav.nav-tabs > li > a {
  line-height: 22px;
}
:global .daterangepicker td.active,
:global .daterangepicker td.active:hover {
  background-image: none;
  background-color: #436b93;
  color: #fff;
}
:global .daterangepicker .calendar th,
:global .daterangepicker .calendar td {
  font-family: inherit;
}
:global .table-striped > tbody > tr:nth-child(odd) > td,
:global .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fbfbfb;
}

:global {
  /*! common */
  /* Sticky Footer */
}
:global .l-with-fixed-top {
  margin-top: 74px;
}
@media (min-width: 1121px) {
  :global .dibs-sidenav {
    z-index: 1 !important;
  }
}
@media (min-width: 1121px) {
  :global .dibs-sidenav.hide-nav {
    display: none;
  }
}
@media (min-width: 1121px) {
  :global div.container-fluid-sidenav {
    margin: 0;
    padding: 0;
  }
}
:global #footer, :global #footer-sidenav {
  clear: both;
}
:global .form-currency-select-view {
  width: 45px;
  margin-right: 10px;
}
:global .form-currency-amount-view {
  width: 150px;
}
:global .form-divider {
  border-bottom: 1px solid #ddd;
  margin-top: -5px;
}
:global html,
:global body {
  height: 100%;
}
:global #outer-row, :global #outer-row-sidenav, :global #outer-row-sidenav-hide {
  padding-top: 85px;
}
@media (min-width: 1121px) {
  :global #outer-row-sidenav, :global #outer-row-sidenav-hide {
    margin: 60px 0 0 253px;
    padding-top: 18px;
    background-color: #f3f3f3;
  }
}
@media (min-width: 1121px) {
  :global #outer-row-sidenav-hide {
    margin: 0;
    padding: 0;
  }
}
:global .no-background {
  background-color: unset !important;
}
:global .hide-header #outer-row, :global .hide-header #outer-row-sidenav, :global .hide-header #outer-row-sidenav-hide {
  padding-top: 0px;
}
:global #wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -30px;
}
:global #footer, :global #footer-sidenav,
:global #push {
  height: 30px;
}
:global #footer, :global #footer-sidenav {
  line-height: 30px;
  background-color: #fbfbfb;
  font-size: 11px;
}
@media (min-width: 1121px) {
  :global #footer-sidenav {
    padding-left: 253px;
  }
}
:global .menu-wrapper .panel-heading {
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}
:global .menu-wrapper .panel-heading i {
  float: right;
}
:global .menu-wrapper .panel-heading:hover {
  background-color: #f3f3f3;
}
:global .hidden {
  display: none;
}
:global .underline {
  text-decoration: underline;
}
:global .nav > li > a.user-link {
  text-transform: none;
}
:global .nav > li > a.user-link .bunsen-icon-mask {
  font-size: 10px;
}
:global .navbar-brand img {
  margin-top: -5px;
  height: 23px;
}
:global #wrapper {
  margin-top: 0;
}
:global #wrapper .hidden {
  display: none;
}
@media (min-width: 1025px) {
  :global #wrapper > .row {
    margin-top: 70px;
  }
}

:global {
  /* COMMON STYLES: */
  /* Turns your elemet into a mock input field. Looks almost like a bootstrap input:  */
  /* similar to hi-light - but adds border:  - mimics the hi-lighted state of bootstrap input fields: */
  /* BACKGROUND COLORS */
  /* I am not currently using bootstrap forms exactly as intended, therefore I have defined my own field states:  */
  /* Attempt to remove colored border for radio buttons- these would only show up in IE8. */
  /* Mock Table (Acts like a bootstrap table but uses divs) */
}
:global .no-min-height {
  min-height: 0 !important;
}
:global body {
  /* Use with inline class or simply inline-block elements */
  /* gives a selector the minimum line height (aka zero) */
}
:global body .pointer {
  cursor: pointer;
}
:global body .top-align {
  vertical-align: top;
}
:global body .icon-xlarge {
  font-size: 1.7em;
}
:global body .valign-with-inputs {
  line-height: 29px;
}
:global body .full-width {
  width: 100%;
}
:global body .small-line-height {
  line-height: 16px;
}
:global body .min-line-height {
  line-height: 0;
}
:global body .text-wrap {
  white-space: normal;
}
:global body .no-padding {
  padding: 0;
}
@media (max-width: 768px) {
  :global {
    /* ----------------------------
    * tab-span* classes, use these with .hidden-tablet to create layouts
    * that adjust column spacing radio when the users screen width
    * gets below 767 pixels. (e.g.  On desktop, you have a row of 3
    * columns they are col-md-3, span4, and col-md-5. And then on tablets
    * and smaller it becomes two rows: one with two columns (tab-col-md-5,
    * tab-col-md-7) and one with one column (tab-col-md-12)
    * ---------------------------- */
    /* unlike hidden tablet, any screen width below 767 will hide this element */
  }
  :global body .fluid-not-responsive [class*=span] {
    float: left;
    margin-left: 1.7624309392%;
    min-height: 0;
  }
  :global body .fluid-not-responsive .col-md-12 {
    width: 100%;
    *width: 99.9468085106%;
  }
  :global body .fluid-not-responsive .col-md-11 {
    width: 91.4364640884%;
    *width: 91.383272599%;
  }
  :global body .fluid-not-responsive .col-md-10 {
    width: 82.8729281768%;
    *width: 82.8197366874%;
  }
  :global body .fluid-not-responsive .col-md-9 {
    width: 74.3093922652%;
    *width: 74.2562007758%;
  }
  :global body .fluid-not-responsive .col-md-8 {
    width: 65.7458563536%;
    *width: 65.6926648642%;
  }
  :global body .fluid-not-responsive .col-md-7 {
    width: 57.182320442%;
    *width: 57.1291289526%;
  }
  :global body .fluid-not-responsive .col-md-6 {
    width: 48.6187845304%;
    *width: 48.565593041%;
  }
  :global body .fluid-not-responsive .col-md-5 {
    width: 40.0552486188%;
    *width: 40.0020571294%;
  }
  :global body .fluid-not-responsive .col-md-4 {
    width: 31.4917127072%;
    *width: 31.4385212178%;
  }
  :global body .fluid-not-responsive .col-md-3 {
    width: 22.9281767956%;
    *width: 22.8749853062%;
  }
  :global body .fluid-not-responsive .col-md-2 {
    width: 14.364640884%;
    *width: 14.3114493946%;
  }
  :global body .fluid-not-responsive .col-md-1 {
    width: 5.8011049724%;
    *width: 5.747913483%;
  }
  :global .row [class*=tab-span] {
    float: left;
    margin-left: 1.7624309392%;
  }
  :global .row .tab-col-md-12 {
    width: 100%;
    *width: 99.9468085106%;
    margin-left: 0;
  }
  :global .row .tab-col-md-11 {
    width: 91.4364640884%;
    *width: 91.383272599%;
  }
  :global .row .tab-col-md-10 {
    width: 82.8729281768%;
    *width: 82.8197366874%;
  }
  :global .row .tab-col-md-9 {
    width: 74.3093922652%;
    *width: 74.2562007758%;
  }
  :global .row .tab-col-md-8 {
    width: 65.7458563536%;
    *width: 65.6926648642%;
  }
  :global .row .tab-col-md-7 {
    width: 57.182320442%;
    *width: 57.1291289526%;
  }
  :global .row .tab-col-md-6 {
    width: 48.6187845304%;
    *width: 48.565593041%;
  }
  :global .row .tab-col-md-5 {
    width: 40.0552486188%;
    *width: 40.0020571294%;
  }
  :global .row .tab-col-md-4 {
    width: 31.4917127072%;
    *width: 31.4385212178%;
  }
  :global .row .tab-col-md-3 {
    width: 22.9281767956%;
    *width: 22.8749853062%;
  }
  :global .row .tab-col-md-2 {
    width: 14.364640884%;
    *width: 14.3114493946%;
  }
  :global .row .tab-col-md-1 {
    width: 5.8011049724%;
    *width: 5.747913483%;
  }
  :global .tab-hide {
    display: none;
  }
  :global .tab-full-width {
    width: 100% !important;
  }
  :global .tab-no-margin {
    margin: 0 !important;
  }
  :global .tab-full-width {
    width: 100% !important;
  }
  :global .tab-no-margin {
    margin: 0 !important;
  }
  :global .tab-no-side-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  :global .tab-no-padd {
    padding: 0 !important;
  }
  :global .tab-no-side-padd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
:global div.loader {
  height: 30px;
  display: none;
}
:global .well-1 {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.05);
}
:global .hidden {
  display: none;
}
:global .inline-block {
  display: inline-block;
}
:global .inline {
  display: inline;
}
:global .rounded {
  border-radius: 4px;
}
:global .shadow {
  box-shadow: 0px 0px 5px 1px rgba(34, 34, 34, 0.1);
}
:global .shadow-small {
  box-shadow: 0px 0px 2px 0px rgba(34, 34, 34, 0.1);
}
:global .hi-light {
  box-shadow: 0px 0px 4px 0px #436b93;
}
:global .fake-input {
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 1px 1px 2px 0px rgba(34, 34, 34, 0.1);
  min-width: 50px;
}
:global .glowing {
  box-shadow: 0px 0px 5px 0px rgba(67, 107, 147, 0.5);
  border: 1px solid #d8ecf5;
}
:global .fake-input.glowing {
  box-shadow: 0px 0px 5px 0px rgba(67, 107, 147, 0.5);
  border: 1px solid #d8ecf5;
}
:global .fake-input.disabled {
  box-shadow: inset 1px 1px 2px 0px rgba(34, 34, 34, 0.1);
  border: 1px solid #bbb;
  background-color: #f3f3f3;
  color: #888;
}
:global .clear {
  clear: both;
  min-width: 100%;
}
:global .mobile-show {
  display: none;
}
@media (max-width: 568px) {
  :global .mobile-show {
    display: block;
  }
}
@media (max-width: 568px) {
  :global .mobile-hide {
    display: none;
  }
}
:global .bg-aqua {
  background-color: #0f8d88;
}
:global .bg-grey {
  background-color: #888;
}
:global input.invalid,
:global select.invalid,
:global textarea.invalid,
:global span.invalid,
:global .chzn-container.invalid a {
  border: 1px solid #cc0000;
  color: #cc0000;
}
:global input.valid,
:global select.valid,
:global textarea.valid,
:global span.valid,
:global .chzn-container.valid a {
  border: 1px solid #66a559;
  color: #66a559;
}
:global .radio input.valid,
:global .radio input.invalid {
  border: 0px;
}
:global .price {
  display: block;
}
:global .mock-table .tbody {
  display: table;
  width: 100%;
}
:global .mock-table .row {
  display: table-row;
}
:global .mock-table .row:nth-child(2n) {
  background-color: #f3f3f3;
}
:global .mock-table .field {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 10px;
  display: table-cell;
  vertical-align: top;
}
:global .mock-table .field:last-child {
  border-right: none;
}
:global .mock-table .headings .field {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}
:global .disable-overlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
:global .lt-ie9 .disable-overlay {
  background-color: #fff;
  filter: alpha(opacity = 70);
}
:global .disabled {
  opacity: 0.75;
}
:global .clear-b {
  clear: both;
}
:global strong.big {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 600;
}
:global .credit {
  text-shadow: 0 0 1px #fff;
  padding-left: 20px;
  margin-bottom: 0;
}
:global .loading {
  margin: 0 auto;
  padding: 15%;
  text-align: center;
  clear: both;
}

@media print {
  :global #dibs-nav,
  :global #footer,
  :global #footer-sidenav,
  :global .omt-title,
  :global #ki_container,
  :global #node-diagnostics {
    display: none;
  }
  :global body {
    font-size: 12px;
  }
  @page {
    :global {
      margin: 0.5cm;
    }
  }
  :global #page {
    margin-top: -61px;
  }
}

a {
  text-decoration: none;
}