.header {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #436b93;
  text-align: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 27px;
}

.imageContainer {
  text-align: center;
  background-color: #f3f3f3;
  width: 540px;
  margin-top: 27px;
  margin-bottom: 36px;
  margin-left: -36px;
  padding: 18px 0;
}

.image {
  width: 334px;
}
