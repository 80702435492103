:local .labelWrapper {
  width: 100%;
  text-align: unset;
  align-items: center;
  display: flex;
}

:local .label {
  flex: 1;
}

:local .stringLabel {
  color: #559b5e;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

:local .stringLabel:hover {
  color: #2a7140;
}

:local .arrow {
  fill: currentColor;
  flex-grow: 0;
  width: 16px;
  height: 16px;
  margin-left: 9px;
  transition: transform .2s ease-in-out;
  transform: translateY(calc(50% - 8px));
}

:local .arrowUp {
  transform: rotate(180deg)translateY(calc(8px - 50%));
}

:local .expandingArea {
  height: 0;
  transition: height .2s linear;
  position: relative;
  overflow-y: auto;
}

:local .noScroll {
  overflow-y: hidden;
}

:local .showOverflow {
  overflow: visible;
}

:local .gradient {
  pointer-events: none;
  z-index: 10;
  width: 100%;
  height: 27px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@supports (--cssVar: red) {
  @supports (-webkit-line-clamp: var(--expandingAreaLineClamp)) {
    :local .lineClamp {
      -webkit-line-clamp: var(--expandingAreaLineClamp);
      display: -webkit-box;
      overflow: hidden;
    }

    :local .lineClamp {
      -webkit-box-orient: vertical;
    }
  }
}

:local .hideContent {
  visibility: hidden;
}
