.customHeaderMessage {
  flex-grow: 100;
  justify-content: space-between;
  align-items: center;
  margin: 18px 54px;
  padding: 0 9px;
  display: flex;
}

@media (width <= 568px) {
  .customHeaderMessage {
    flex-direction: column;
    align-items: flex-start;
  }
}

.customHeaderMessage.separators {
  border-left: thin solid #ddd;
  border-right: thin solid #ddd;
}

.assistance, .bannerText {
  margin: 0;
  padding: 0;
}

.bannerText {
  letter-spacing: -.5px;
  text-transform: none;
  margin: 0;
  padding-right: 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
}

@media (width <= 768px) {
  .bannerText {
    letter-spacing: -.5px;
    font-family: Cardinal Classic Short, Georgia, "serif";
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }
}

@media (width <= 568px) {
  .bannerText {
    display: none;
  }
}

.linkContainer {
  margin-top: 9px;
  margin-right: 27px;
}

.supportLink {
  color: #436b93;
  border-right: thin solid #ddd;
  padding-left: 9px;
  padding-right: 9px;
}

.supportLink:hover {
  color: #204664;
}

.supportLink:first-of-type {
  padding-left: 0;
}

.supportLink:last-of-type {
  border-right: none;
  padding-right: 0;
}

.prompt {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

@media (width <= 768px) {
  .prompt {
    display: none;
  }
}

.assistance {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-transform: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@media (width <= 568px) {
  .assistance {
    display: none;
  }
}

.phone {
  display: inline-block;
}
