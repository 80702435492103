.flexSpacer {
  flex-grow: 100;
}

.mobileCreateListingItemsContainer {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.mobileCreateListingItemsList {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 27px 0;
}

@media (min-width: 1121px) and (max-width: 1339px) {
  .circlePlus {
    margin-right: 0;
    min-width: 20px;
  }
}

@media (min-width: 1121px) and (max-width: 1339px) {
  .label {
    display: none;
  }
}

/**
    NavBarButtons are only displayed for Dealer Navigation
    For Internal Navigation, it is not displayed
 */
.navBarButton {
  min-width: auto;
}
@media (max-width: 1120px) {
  .navBarButton .button {
    --padding: 9px;
    border: solid thin #436b93;
  }
  .navBarButton .button:hover, .navBarButton .button:focus {
    background-color: #436b93;
    color: #fff;
  }
  .navBarButton .button:hover .circlePlus, .navBarButton .button:focus .circlePlus {
    fill: #fff;
  }
  .navBarButton .button:hover * {
    transition: all 0.3s ease;
    fill: #fff;
    color: #fff;
  }
  .navBarButton.buttonItem a {
    width: 100%;
  }
  .navBarButton.navbarDropdown {
    flex-direction: column;
  }
}

.button {
  width: auto;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  display: inline-flex;
  white-space: nowrap;
  flex: 0 1 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  padding: var(--padding, 0);
}

.createListingText {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}

.buttonItem {
  width: auto;
}
.buttonItem a {
  color: #436b93;
}