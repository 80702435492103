.flexSpacer {
  flex-grow: 100;
}

[data-navtype*="dealer"] {
  --logoFill: #222;
  --borderTopColor: #436b93;
  --navBackgroundColor: #fff;
  --outlineColor: #204664;
  --brandTextMinHeight: 18px;
  --focusDecoration: underline;
  --navHeadingWidth: 100%;
}

[data-navtype*="internal"] {
  --navBackgroundColor: #222;
  --borderTopColor: #559b5e;
  --logoFill: #fff;
  --outlineColor: #2a7140;
  --navHeadingWidth: auto;
}

[data-navtype] {
  background-color: var(--navBackgroundColor);
  border-top: solid 3px var(--borderTopColor);
  z-index: 980;
  min-height: 68px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 1px 2px 8px #22222240;
}

[data-navtype] :focus {
  outline: var(--outlineColor) auto 3px;
}

@-moz-document url-prefix() {
  [data-navtype] a:focus {
    -webkit-text-decoration: var(--focusDecoration, none);
    text-decoration: var(--focusDecoration, none);
    outline: none;
  }

  [data-navtype] button:focus {
    outline-width: thin;
    outline-style: dotted;
  }
}

@media (width <= 1120px) {
  [data-navtype].dealerNav {
    flex-direction: column;
  }

  [data-navtype].dealerNav .navHeading {
    padding-right: 36px;
  }
}

@media (width >= 1121px) {
  [data-navtype].dealerNav {
    align-items: center;
    padding-right: 36px;
  }
}

@media (width >= 769px) {
  [data-navtype].internalNav {
    align-items: center;
    padding-right: 36px;
  }
}

@media (width <= 768px) {
  [data-navtype].internalNav {
    flex-direction: column;
  }

  [data-navtype].internalNav .navHeading {
    padding-right: 36px;
  }
}

[data-navtype].isInSideBarNavTest {
  min-height: 60px;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #ddd;
  padding-left: 280px;
}

.navHeading {
  display: flex;
}

@media (width <= 1120px) {
  .navHeading {
    width: var(--navHeadingWidth);
  }
}

.logo {
  fill: var(--logoFill, #222);
  width: 80px;
}

.brand {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  min-height: 65px;
  display: flex;
}

.brandText {
  min-height: var(--brandTextMinHeight, none);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #222;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}
