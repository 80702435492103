.question {
  white-space: pre-wrap;
  text-transform: uppercase;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.arrowIcon {
  vertical-align: middle;
  width: 11px;
  height: 11px;
  margin-left: 6px;
}
