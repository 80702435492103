.flexSpacer {
  flex-grow: 100;
}

.mobileCreateListingItemsContainer {
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.mobileCreateListingItemsList {
  flex-direction: column;
  gap: 18px;
  padding: 27px 0;
  display: flex;
}

@media (width >= 1121px) and (width <= 1339px) {
  .circlePlus {
    min-width: 20px;
    margin-right: 0;
  }

  .label {
    display: none;
  }
}

.navBarButton {
  min-width: auto;
}

@media (width <= 1120px) {
  .navBarButton .button {
    --padding: 9px;
    border: thin solid #436b93;
  }

  .navBarButton .button:hover, .navBarButton .button:focus {
    color: #fff;
    background-color: #436b93;
  }

  .navBarButton .button:hover .circlePlus, .navBarButton .button:focus .circlePlus {
    fill: #fff;
  }

  .navBarButton .button:hover * {
    fill: #fff;
    color: #fff;
    transition: all .3s;
  }

  .navBarButton.buttonItem a {
    width: 100%;
  }

  .navBarButton.navbarDropdown {
    flex-direction: column;
  }
}

.button {
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  width: auto;
  padding: 0;
  padding: var(--padding, 0);
  flex: 0 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  display: inline-flex;
}

.createListingText {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.buttonItem {
  width: auto;
}

.buttonItem a {
  color: #436b93;
}
