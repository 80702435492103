.expandingAreaLabel {
  outline: none !important;
}

.expandingAreaLabel:hover {
  background-color: #f3f3f3;
}

.expandingAreaArrow {
  fill: #222;
}
