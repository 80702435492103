.modal {
  overflow: visible;
}

.description {
  margin-bottom: 18px;
}

.datePickerWrapper {
  margin-bottom: 18px;
}

.downloadButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 18px;
  margin-right: 9px;
  margin-left: -27px;
}

.serverError {
  text-align: center;
  color: #950808;
  margin-top: 9px;
  margin-bottom: 0;
}