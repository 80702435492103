.accountManager {
  white-space: normal;
  color: #222;
}

.accountManager:hover {
  color: #222;
}

.body {
  margin: 0;
}

.email {
  text-transform: none;
}
