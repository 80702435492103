.tooltip {
  min-width: 220px;
  top: 100%;
  left: var(--tooltipOffsetLeft, 0);
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  border: thin solid #ddd;
  margin: 0;
  padding: 18px;
  list-style: none;
  transition: all .3s;
  position: absolute;
  box-shadow: 1px 2px 8px #22222240;
}

.tooltip:after {
  top: -4px;
  left: var(--tooltipArrowOffset, 14px);
  content: "";
  filter: drop-shadow(0 -1px #ddd);
  border-bottom: 10px solid #fff;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  transition: all .3s;
  display: inline-block;
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
}

.tooltip.right {
  left: auto;
  right: 9px;
}

.tooltip.right:after {
  width: 1px;
  left: auto;
  right: -5px;
}

.tooltip.middleArrow:after {
  --tooltipArrowOffset: calc(50% - 7px);
}

.tooltip.open {
  visibility: visible;
  opacity: 1;
}
