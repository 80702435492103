.tooltip {
  box-shadow: 1px 2px 8px 0 rgba(34, 34, 34, 0.25);
  border: solid thin #ddd;
  background-color: #fff;
  min-width: 220px;
  margin: 0;
  padding: 18px;
  position: absolute;
  top: 100%;
  left: var(--tooltipOffsetLeft, 0);
  list-style: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}
.tooltip:after {
  transition: all 0.3s ease;
  position: absolute;
  top: -4px;
  left: var(--tooltipArrowOffset, 14px);
  transform: translateX(-50%);
  display: inline-block;
  content: "";
  top: -10px;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  filter: drop-shadow(0 -1px 0 #ddd);
}
.tooltip.right {
  left: auto;
  right: 9px;
}
.tooltip.right:after {
  left: auto;
  right: -5px;
  width: 1px;
}
.tooltip.middleArrow:after {
  --tooltipArrowOffset: calc(50% - 7px);
}
.tooltip.open {
  visibility: visible;
  opacity: 1;
}