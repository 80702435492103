.customHeaderMessage {
  display: flex;
  flex-grow: 100;
  align-items: center;
  justify-content: space-between;
  margin: 18px 54px 18px;
  padding: 0 9px;
}
@media (max-width: 568px) {
  .customHeaderMessage {
    flex-direction: column;
    align-items: flex-start;
  }
}
.customHeaderMessage.separators {
  border-left: solid thin #ddd;
  border-right: solid thin #ddd;
}

.assistance,
.bannerText {
  padding: 0;
  margin: 0;
}

.bannerText {
  padding-right: 9px;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-transform: none;
  margin: 0;
  line-height: 36px;
}
@media (max-width: 768px) {
  .bannerText {
    font-family: "Cardinal Classic Short", "Georgia", "serif";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.5px;
  }
}
@media (max-width: 568px) {
  .bannerText {
    display: none;
  }
}

.linkContainer {
  margin-top: 9px;
  margin-right: 27px;
}

.supportLink {
  padding-left: 9px;
  padding-right: 9px;
  border-right: solid thin #ddd;
  color: #436b93;
}
.supportLink:hover {
  color: #204664;
}
.supportLink:first-of-type {
  padding-left: 0;
}
.supportLink:last-of-type {
  border-right: none;
  padding-right: 0;
}

.prompt {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .prompt {
    display: none;
  }
}

.assistance {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: none;
}
@media (max-width: 568px) {
  .assistance {
    display: none;
  }
}

.phone {
  display: inline-block;
}