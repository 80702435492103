.datepicker {
  padding: 18px;
}

.dropdownWrapper {
  margin-top: -1px;
}

.calendarIcon {
  width: 19px;
  height: 18px;
}
