.link {
  line-height: unset;
  cursor: pointer;
  color: #559b5e;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.link:hover {
  color: #222;
}

.underline {
  text-decoration: underline;
}

.dashed {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
  text-decoration-thickness: .5px;
}
