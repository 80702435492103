@charset "UTF-8";
:global {
  /* ADDITIONAL TEXT STYLES */
}
@media print {
  :global * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  :global a,
  :global a:visited {
    text-decoration: underline;
  }
  :global a[href]:after {
    content: " (" attr(href) ")";
  }
  :global abbr[title]:after {
    content: " (" attr(title) ")";
  }
  :global a[href^="javascript:"]:after,
  :global a[href^="#"]:after {
    content: "";
  }
  :global pre,
  :global blockquote {
    border: 1px solid #888;
    page-break-inside: avoid;
  }
  :global thead {
    display: table-header-group;
  }
  :global tr,
  :global img {
    page-break-inside: avoid;
  }
  :global img {
    max-width: 100% !important;
  }
  @page {
    :global {
      margin: 2cm 0.5cm;
    }
  }
  :global p,
  :global h2,
  :global h3 {
    orphans: 3;
    widows: 3;
  }
  :global h2,
  :global h3 {
    page-break-after: avoid;
  }
  :global select {
    background: #fff !important;
  }
  :global .navbar {
    display: none;
  }
  :global .table td,
  :global .table th {
    background-color: #fff !important;
  }
  :global .btn > .caret,
  :global .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  :global .label {
    border: 1px solid #000;
  }
  :global .table {
    border-collapse: collapse !important;
  }
  :global .table-bordered th,
  :global .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
:global img {
  vertical-align: middle;
}
:global .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
:global .img-rounded {
  border-radius: 2px;
}
:global .img-thumbnail, :global .thumbnail {
  padding: 4px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
:global .img-circle {
  border-radius: 50%;
}
:global hr {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  border-top: 1px solid #ddd;
}
:global .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
:global p {
  margin: 0 0 10px;
}
:global .lead {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}
@media (max-width: 768px) {
  :global .lead {
    font-size: 18px;
  }
}
:global small,
:global .small {
  font-size: 12px;
}
:global .medium {
  font-size: 16px;
}
:global .large {
  font-size: 18px;
}
:global cite {
  font-style: normal;
}
:global .text-muted {
  color: #888;
}
:global .lightly-muted {
  color: #ddd;
}
:global .text-primary {
  color: #559b5e;
}
:global .text-warning {
  color: #ffc100;
}
:global .text-danger,
:global .text-error {
  color: #cc0000;
}
:global .text-success {
  color: #66a559;
}
:global .text-info {
  color: #436b93;
}
:global .text-aqua {
  color: #0f8d88;
}
:global .text-grey {
  color: #888;
}
:global .text-yellow {
  color: #c2a661;
}
:global .text-red {
  color: #cc0000;
}
:global .text-green {
  color: #559b5e;
}
:global .text-left {
  text-align: left;
}
:global .text-right {
  text-align: right;
}
:global .text-center {
  text-align: center;
}
:global .text-upper {
  text-transform: uppercase;
}
:global .text-lower {
  text-transform: lowercase;
}
:global .text-notrans {
  text-transform: none;
}
:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6,
:global .h1,
:global .h2,
:global .h3,
:global .h4,
:global .h5,
:global .h6 {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
  color: inherit;
}
:global h1 small,
:global h1 .small,
:global h2 small,
:global h2 .small,
:global h3 small,
:global h3 .small,
:global h4 small,
:global h4 .small,
:global h5 small,
:global h5 .small,
:global h6 small,
:global h6 .small,
:global .h1 small,
:global .h1 .small,
:global .h2 small,
:global .h2 .small,
:global .h3 small,
:global .h3 .small,
:global .h4 small,
:global .h4 .small,
:global .h5 small,
:global .h5 .small,
:global .h6 small,
:global .h6 .small {
  line-height: 1;
  color: #888;
}
:global h1,
:global h2,
:global h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
:global h1 small,
:global h1 .small,
:global h2 small,
:global h2 .small,
:global h3 small,
:global h3 .small {
  font-size: 65%;
}
:global h4,
:global h5,
:global h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
:global h4 small,
:global h4 .small,
:global h5 small,
:global h5 .small,
:global h6 small,
:global h6 .small {
  font-size: 75%;
}
:global h1,
:global .h1 {
  font-size: 32px;
}
:global h2,
:global .h2 {
  font-size: 28px;
}
:global h3,
:global .h3 {
  font-size: 24px;
}
:global h4,
:global .h4 {
  font-size: 20px;
}
:global h5,
:global .h5 {
  font-size: 16px;
}
:global h6,
:global .h6 {
  font-size: 12px;
}
:global .page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #ddd;
}
:global ul,
:global ol {
  margin-top: 0;
  margin-bottom: 10px;
}
:global ul ul,
:global ul ol,
:global ol ul,
:global ol ol {
  margin-bottom: 0;
}
:global .list-unstyled, :global .list-inline {
  padding-left: 0;
  list-style: none;
}
:global .list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
:global .list-inline > li:first-child {
  padding-left: 0;
}
:global .list-inline > li:last-child {
  padding-right: 0;
}
:global dl {
  margin-bottom: 20px;
}
:global dt,
:global dd {
  line-height: 1.5;
}
:global dt {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}
:global dd {
  margin-left: 0;
}
@media (min-width: 769px) {
  :global .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :global .dl-horizontal dd {
    margin-left: 180px;
  }
  :global .dl-horizontal dd:before, :global .dl-horizontal dd:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  :global .dl-horizontal dd:after {
    clear: both;
  }
}
:global abbr[title],
:global abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #888;
}
:global abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
:global blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #ddd;
}
:global blockquote p {
  font-size: 18px;
  line-height: 1.25;
}
:global blockquote p:last-child {
  margin-bottom: 0;
}
:global blockquote small {
  display: block;
  line-height: 1.5;
  color: #888;
}
:global blockquote small:before {
  content: "— ";
}
:global blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #ddd;
  border-left: 0;
}
:global blockquote.pull-right p,
:global blockquote.pull-right small,
:global blockquote.pull-right .small {
  text-align: right;
}
:global blockquote.pull-right small:before,
:global blockquote.pull-right .small:before {
  content: "";
}
:global blockquote.pull-right small:after,
:global blockquote.pull-right .small:after {
  content: " —";
}
:global blockquote:before,
:global blockquote:after {
  content: "";
}
:global address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.5;
}
:global .neue {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
}
:global .bryant {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
}
:global code,
:global kbd,
:global pre,
:global samp {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}
:global code {
  padding: 2px 4px;
  font-size: 90%;
  color: #cc0000;
  background-color: #fbfbfb;
  white-space: nowrap;
  border-radius: 2px;
}
:global pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
  color: #222;
  background-color: #fbfbfb;
  border: 1px solid #bbb;
  border-radius: 2px;
}
:global pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
:global .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
:global .container,
:global .container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
:global .container:before, :global .container:after,
:global .container-fluid:before,
:global .container-fluid:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .container:after,
:global .container-fluid:after {
  clear: both;
}
@media print {
  :global .container,
  :global .container-fluid {
    margin-right: 0;
    margin-left: 0;
  }
}
:global .row {
  margin-left: -15px;
  margin-right: -15px;
}
:global .row:before, :global .row:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .row:after {
  clear: both;
}
:global .col-xxs-12, :global .col-xs-12, :global .col-sm-12, :global .col-md-12, :global .col-lg-12, :global .col-xlg-12, :global .col-xxs-11, :global .col-xs-11, :global .col-sm-11, :global .col-md-11, :global .col-lg-11, :global .col-xlg-11, :global .col-xxs-10, :global .col-xs-10, :global .col-sm-10, :global .col-md-10, :global .col-lg-10, :global .col-xlg-10, :global .col-xxs-9, :global .col-xs-9, :global .col-sm-9, :global .col-md-9, :global .col-lg-9, :global .col-xlg-9, :global .col-xxs-8, :global .col-xs-8, :global .col-sm-8, :global .col-md-8, :global .col-lg-8, :global .col-xlg-8, :global .col-xxs-7, :global .col-xs-7, :global .col-sm-7, :global .col-md-7, :global .col-lg-7, :global .col-xlg-7, :global .col-xxs-6, :global .col-xs-6, :global .col-sm-6, :global .col-md-6, :global .col-lg-6, :global .col-xlg-6, :global .col-xxs-5, :global .col-xs-5, :global .col-sm-5, :global .col-md-5, :global .col-lg-5, :global .col-xlg-5, :global .col-xxs-4, :global .col-xs-4, :global .col-sm-4, :global .col-md-4, :global .col-lg-4, :global .col-xlg-4, :global .col-xxs-3, :global .col-xs-3, :global .col-sm-3, :global .col-md-3, :global .col-lg-3, :global .col-xlg-3, :global .col-xxs-2, :global .col-xs-2, :global .col-sm-2, :global .col-md-2, :global .col-lg-2, :global .col-xlg-2, :global .col-xxs-1, :global .col-xs-1, :global .col-sm-1, :global .col-md-1, :global .col-lg-1, :global .col-xlg-1 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}
:global .col-xs-12, :global .col-xs-11, :global .col-xs-10, :global .col-xs-9, :global .col-xs-8, :global .col-xs-7, :global .col-xs-6, :global .col-xs-5, :global .col-xs-4, :global .col-xs-3, :global .col-xs-2, :global .col-xs-1 {
  float: left;
}
:global .col-xs-1 {
  width: 8.3333333333%;
}
:global .col-xs-2 {
  width: 16.6666666667%;
}
:global .col-xs-3 {
  width: 25%;
}
:global .col-xs-4 {
  width: 33.3333333333%;
}
:global .col-xs-5 {
  width: 41.6666666667%;
}
:global .col-xs-6 {
  width: 50%;
}
:global .col-xs-7 {
  width: 58.3333333333%;
}
:global .col-xs-8 {
  width: 66.6666666667%;
}
:global .col-xs-9 {
  width: 75%;
}
:global .col-xs-10 {
  width: 83.3333333333%;
}
:global .col-xs-11 {
  width: 91.6666666667%;
}
:global .col-xs-12 {
  width: 100%;
}
:global .col-xs-pull-0 {
  right: 0%;
}
:global .col-xs-pull-1 {
  right: 8.3333333333%;
}
:global .col-xs-pull-2 {
  right: 16.6666666667%;
}
:global .col-xs-pull-3 {
  right: 25%;
}
:global .col-xs-pull-4 {
  right: 33.3333333333%;
}
:global .col-xs-pull-5 {
  right: 41.6666666667%;
}
:global .col-xs-pull-6 {
  right: 50%;
}
:global .col-xs-pull-7 {
  right: 58.3333333333%;
}
:global .col-xs-pull-8 {
  right: 66.6666666667%;
}
:global .col-xs-pull-9 {
  right: 75%;
}
:global .col-xs-pull-10 {
  right: 83.3333333333%;
}
:global .col-xs-pull-11 {
  right: 91.6666666667%;
}
:global .col-xs-pull-12 {
  right: 100%;
}
:global .col-xs-push-0 {
  left: 0%;
}
:global .col-xs-push-1 {
  left: 8.3333333333%;
}
:global .col-xs-push-2 {
  left: 16.6666666667%;
}
:global .col-xs-push-3 {
  left: 25%;
}
:global .col-xs-push-4 {
  left: 33.3333333333%;
}
:global .col-xs-push-5 {
  left: 41.6666666667%;
}
:global .col-xs-push-6 {
  left: 50%;
}
:global .col-xs-push-7 {
  left: 58.3333333333%;
}
:global .col-xs-push-8 {
  left: 66.6666666667%;
}
:global .col-xs-push-9 {
  left: 75%;
}
:global .col-xs-push-10 {
  left: 83.3333333333%;
}
:global .col-xs-push-11 {
  left: 91.6666666667%;
}
:global .col-xs-push-12 {
  left: 100%;
}
:global .col-xs-offset-0 {
  margin-left: 0%;
}
:global .col-xs-offset-1 {
  margin-left: 8.3333333333%;
}
:global .col-xs-offset-2 {
  margin-left: 16.6666666667%;
}
:global .col-xs-offset-3 {
  margin-left: 25%;
}
:global .col-xs-offset-4 {
  margin-left: 33.3333333333%;
}
:global .col-xs-offset-5 {
  margin-left: 41.6666666667%;
}
:global .col-xs-offset-6 {
  margin-left: 50%;
}
:global .col-xs-offset-7 {
  margin-left: 58.3333333333%;
}
:global .col-xs-offset-8 {
  margin-left: 66.6666666667%;
}
:global .col-xs-offset-9 {
  margin-left: 75%;
}
:global .col-xs-offset-10 {
  margin-left: 83.3333333333%;
}
:global .col-xs-offset-11 {
  margin-left: 91.6666666667%;
}
:global .col-xs-offset-12 {
  margin-left: 100%;
}
@media (min-width: 569px) {
  :global .container {
    width: 539px;
  }
  :global .col-sm-12, :global .col-sm-11, :global .col-sm-10, :global .col-sm-9, :global .col-sm-8, :global .col-sm-7, :global .col-sm-6, :global .col-sm-5, :global .col-sm-4, :global .col-sm-3, :global .col-sm-2, :global .col-sm-1 {
    float: left;
  }
  :global .col-sm-1 {
    width: 8.3333333333%;
  }
  :global .col-sm-2 {
    width: 16.6666666667%;
  }
  :global .col-sm-3 {
    width: 25%;
  }
  :global .col-sm-4 {
    width: 33.3333333333%;
  }
  :global .col-sm-5 {
    width: 41.6666666667%;
  }
  :global .col-sm-6 {
    width: 50%;
  }
  :global .col-sm-7 {
    width: 58.3333333333%;
  }
  :global .col-sm-8 {
    width: 66.6666666667%;
  }
  :global .col-sm-9 {
    width: 75%;
  }
  :global .col-sm-10 {
    width: 83.3333333333%;
  }
  :global .col-sm-11 {
    width: 91.6666666667%;
  }
  :global .col-sm-12 {
    width: 100%;
  }
  :global .col-sm-pull-0 {
    right: 0%;
  }
  :global .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  :global .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  :global .col-sm-pull-3 {
    right: 25%;
  }
  :global .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  :global .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  :global .col-sm-pull-6 {
    right: 50%;
  }
  :global .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  :global .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  :global .col-sm-pull-9 {
    right: 75%;
  }
  :global .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  :global .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  :global .col-sm-pull-12 {
    right: 100%;
  }
  :global .col-sm-push-0 {
    left: 0%;
  }
  :global .col-sm-push-1 {
    left: 8.3333333333%;
  }
  :global .col-sm-push-2 {
    left: 16.6666666667%;
  }
  :global .col-sm-push-3 {
    left: 25%;
  }
  :global .col-sm-push-4 {
    left: 33.3333333333%;
  }
  :global .col-sm-push-5 {
    left: 41.6666666667%;
  }
  :global .col-sm-push-6 {
    left: 50%;
  }
  :global .col-sm-push-7 {
    left: 58.3333333333%;
  }
  :global .col-sm-push-8 {
    left: 66.6666666667%;
  }
  :global .col-sm-push-9 {
    left: 75%;
  }
  :global .col-sm-push-10 {
    left: 83.3333333333%;
  }
  :global .col-sm-push-11 {
    left: 91.6666666667%;
  }
  :global .col-sm-push-12 {
    left: 100%;
  }
  :global .col-sm-offset-0 {
    margin-left: 0%;
  }
  :global .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  :global .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  :global .col-sm-offset-3 {
    margin-left: 25%;
  }
  :global .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  :global .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  :global .col-sm-offset-6 {
    margin-left: 50%;
  }
  :global .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  :global .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  :global .col-sm-offset-9 {
    margin-left: 75%;
  }
  :global .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  :global .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  :global .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 769px) {
  :global .container {
    width: 739px;
  }
  :global .col-md-12, :global .col-md-11, :global .col-md-10, :global .col-md-9, :global .col-md-8, :global .col-md-7, :global .col-md-6, :global .col-md-5, :global .col-md-4, :global .col-md-3, :global .col-md-2, :global .col-md-1 {
    float: left;
  }
  :global .col-md-1 {
    width: 8.3333333333%;
  }
  :global .col-md-2 {
    width: 16.6666666667%;
  }
  :global .col-md-3 {
    width: 25%;
  }
  :global .col-md-4 {
    width: 33.3333333333%;
  }
  :global .col-md-5 {
    width: 41.6666666667%;
  }
  :global .col-md-6 {
    width: 50%;
  }
  :global .col-md-7 {
    width: 58.3333333333%;
  }
  :global .col-md-8 {
    width: 66.6666666667%;
  }
  :global .col-md-9 {
    width: 75%;
  }
  :global .col-md-10 {
    width: 83.3333333333%;
  }
  :global .col-md-11 {
    width: 91.6666666667%;
  }
  :global .col-md-12 {
    width: 100%;
  }
  :global .col-md-pull-0 {
    right: 0%;
  }
  :global .col-md-pull-1 {
    right: 8.3333333333%;
  }
  :global .col-md-pull-2 {
    right: 16.6666666667%;
  }
  :global .col-md-pull-3 {
    right: 25%;
  }
  :global .col-md-pull-4 {
    right: 33.3333333333%;
  }
  :global .col-md-pull-5 {
    right: 41.6666666667%;
  }
  :global .col-md-pull-6 {
    right: 50%;
  }
  :global .col-md-pull-7 {
    right: 58.3333333333%;
  }
  :global .col-md-pull-8 {
    right: 66.6666666667%;
  }
  :global .col-md-pull-9 {
    right: 75%;
  }
  :global .col-md-pull-10 {
    right: 83.3333333333%;
  }
  :global .col-md-pull-11 {
    right: 91.6666666667%;
  }
  :global .col-md-pull-12 {
    right: 100%;
  }
  :global .col-md-push-0 {
    left: 0%;
  }
  :global .col-md-push-1 {
    left: 8.3333333333%;
  }
  :global .col-md-push-2 {
    left: 16.6666666667%;
  }
  :global .col-md-push-3 {
    left: 25%;
  }
  :global .col-md-push-4 {
    left: 33.3333333333%;
  }
  :global .col-md-push-5 {
    left: 41.6666666667%;
  }
  :global .col-md-push-6 {
    left: 50%;
  }
  :global .col-md-push-7 {
    left: 58.3333333333%;
  }
  :global .col-md-push-8 {
    left: 66.6666666667%;
  }
  :global .col-md-push-9 {
    left: 75%;
  }
  :global .col-md-push-10 {
    left: 83.3333333333%;
  }
  :global .col-md-push-11 {
    left: 91.6666666667%;
  }
  :global .col-md-push-12 {
    left: 100%;
  }
  :global .col-md-offset-0 {
    margin-left: 0%;
  }
  :global .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  :global .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  :global .col-md-offset-3 {
    margin-left: 25%;
  }
  :global .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  :global .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  :global .col-md-offset-6 {
    margin-left: 50%;
  }
  :global .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  :global .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  :global .col-md-offset-9 {
    margin-left: 75%;
  }
  :global .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  :global .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  :global .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1025px) {
  :global .container {
    width: 995px;
  }
  :global .col-lg-12, :global .col-lg-11, :global .col-lg-10, :global .col-lg-9, :global .col-lg-8, :global .col-lg-7, :global .col-lg-6, :global .col-lg-5, :global .col-lg-4, :global .col-lg-3, :global .col-lg-2, :global .col-lg-1 {
    float: left;
  }
  :global .col-lg-1 {
    width: 8.3333333333%;
  }
  :global .col-lg-2 {
    width: 16.6666666667%;
  }
  :global .col-lg-3 {
    width: 25%;
  }
  :global .col-lg-4 {
    width: 33.3333333333%;
  }
  :global .col-lg-5 {
    width: 41.6666666667%;
  }
  :global .col-lg-6 {
    width: 50%;
  }
  :global .col-lg-7 {
    width: 58.3333333333%;
  }
  :global .col-lg-8 {
    width: 66.6666666667%;
  }
  :global .col-lg-9 {
    width: 75%;
  }
  :global .col-lg-10 {
    width: 83.3333333333%;
  }
  :global .col-lg-11 {
    width: 91.6666666667%;
  }
  :global .col-lg-12 {
    width: 100%;
  }
  :global .col-lg-pull-0 {
    right: 0%;
  }
  :global .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  :global .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  :global .col-lg-pull-3 {
    right: 25%;
  }
  :global .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  :global .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  :global .col-lg-pull-6 {
    right: 50%;
  }
  :global .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  :global .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  :global .col-lg-pull-9 {
    right: 75%;
  }
  :global .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  :global .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  :global .col-lg-pull-12 {
    right: 100%;
  }
  :global .col-lg-push-0 {
    left: 0%;
  }
  :global .col-lg-push-1 {
    left: 8.3333333333%;
  }
  :global .col-lg-push-2 {
    left: 16.6666666667%;
  }
  :global .col-lg-push-3 {
    left: 25%;
  }
  :global .col-lg-push-4 {
    left: 33.3333333333%;
  }
  :global .col-lg-push-5 {
    left: 41.6666666667%;
  }
  :global .col-lg-push-6 {
    left: 50%;
  }
  :global .col-lg-push-7 {
    left: 58.3333333333%;
  }
  :global .col-lg-push-8 {
    left: 66.6666666667%;
  }
  :global .col-lg-push-9 {
    left: 75%;
  }
  :global .col-lg-push-10 {
    left: 83.3333333333%;
  }
  :global .col-lg-push-11 {
    left: 91.6666666667%;
  }
  :global .col-lg-push-12 {
    left: 100%;
  }
  :global .col-lg-offset-0 {
    margin-left: 0%;
  }
  :global .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  :global .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  :global .col-lg-offset-3 {
    margin-left: 25%;
  }
  :global .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  :global .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  :global .col-lg-offset-6 {
    margin-left: 50%;
  }
  :global .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  :global .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  :global .col-lg-offset-9 {
    margin-left: 75%;
  }
  :global .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  :global .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  :global .col-lg-offset-12 {
    margin-left: 100%;
  }
}
:global .col-sm-gutter-left {
  padding-left: 6px;
}
:global .col-sm-gutter-right {
  padding-right: 6px;
}
:global table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
:global th {
  text-align: left;
}
:global .table {
  width: 100%;
  margin-bottom: 20px;
}
:global .table > thead > tr > th,
:global .table > thead > tr > td,
:global .table > tbody > tr > th,
:global .table > tbody > tr > td,
:global .table > tfoot > tr > th,
:global .table > tfoot > tr > td {
  padding: 9px;
  line-height: 1.5;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
:global .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
:global .table > caption + thead > tr:first-child > th,
:global .table > caption + thead > tr:first-child > td,
:global .table > colgroup + thead > tr:first-child > th,
:global .table > colgroup + thead > tr:first-child > td,
:global .table > thead:first-child > tr:first-child > th,
:global .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
:global .table > tbody + tbody {
  border-top: 2px solid #ddd;
}
:global .table .table {
  background-color: #fff;
}
:global .table-condensed > thead > tr > th,
:global .table-condensed > thead > tr > td,
:global .table-condensed > tbody > tr > th,
:global .table-condensed > tbody > tr > td,
:global .table-condensed > tfoot > tr > th,
:global .table-condensed > tfoot > tr > td {
  padding: 5px;
}
:global .table-bordered {
  border: 1px solid #ddd;
}
:global .table-bordered > thead > tr > th,
:global .table-bordered > thead > tr > td,
:global .table-bordered > tbody > tr > th,
:global .table-bordered > tbody > tr > td,
:global .table-bordered > tfoot > tr > th,
:global .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
:global .table-bordered > thead > tr > th,
:global .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
:global .table-borderless > thead > tr > th,
:global .table-borderless > thead > tr > td,
:global .table-borderless > tbody > tr > th,
:global .table-borderless > tbody > tr > td,
:global .table-borderless > tfoot > tr > th,
:global .table-borderless > tfoot > tr > td {
  border: 0;
}
:global .table-no-padding {
  margin-bottom: 0 !important;
}
:global .table-no-padding > thead > tr > th,
:global .table-no-padding > thead > tr > td,
:global .table-no-padding > tbody > tr > th,
:global .table-no-padding > tbody > tr > td,
:global .table-no-padding > tfoot > tr > th,
:global .table-no-padding > tfoot > tr > td {
  padding: 0 !important;
  vertical-align: bottom;
}
:global .table-no-left-padd > thead > tr > th:first-child,
:global .table-no-left-padd > thead > tr > td:first-child,
:global .table-no-left-padd > tbody > tr > th:first-child,
:global .table-no-left-padd > tbody > tr > td:first-child,
:global .table-no-left-padd > tfoot > tr > th:first-child,
:global .table-no-left-padd > tfoot > tr > td:first-child {
  padding-left: 0 !important;
}
:global .table-striped > tbody > tr:nth-child(odd) > td,
:global .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f3f3f3;
}
:global .table-hover > tbody > tr:hover > td,
:global .table-hover > tbody > tr:hover > th {
  background-color: #f3f3f3;
}
:global table col[class*=col-] {
  float: none;
  display: table-column;
}
:global table td[class*=col-],
:global table th[class*=col-] {
  float: none;
  display: table-cell;
}
:global .table > thead > tr > td.active,
:global .table > thead > tr > th.active, :global .table > thead > tr.active > td, :global .table > thead > tr.active > th,
:global .table > tbody > tr > td.active,
:global .table > tbody > tr > th.active,
:global .table > tbody > tr.active > td,
:global .table > tbody > tr.active > th,
:global .table > tfoot > tr > td.active,
:global .table > tfoot > tr > th.active,
:global .table > tfoot > tr.active > td,
:global .table > tfoot > tr.active > th {
  background-color: #f3f3f3;
}
:global .table > thead > tr > td.success,
:global .table > thead > tr > th.success, :global .table > thead > tr.success > td, :global .table > thead > tr.success > th,
:global .table > tbody > tr > td.success,
:global .table > tbody > tr > th.success,
:global .table > tbody > tr.success > td,
:global .table > tbody > tr.success > th,
:global .table > tfoot > tr > td.success,
:global .table > tfoot > tr > th.success,
:global .table > tfoot > tr.success > td,
:global .table > tfoot > tr.success > th {
  background-color: #b8e7c8;
}
:global .table-hover > tbody > tr > td.success:hover,
:global .table-hover > tbody > tr > th.success:hover, :global .table-hover > tbody > tr.success:hover > td, :global .table-hover > tbody > tr.success:hover > th {
  background-color: #a5e1b9;
}
:global .table > thead > tr > td.danger,
:global .table > thead > tr > th.danger, :global .table > thead > tr.danger > td, :global .table > thead > tr.danger > th,
:global .table > tbody > tr > td.danger,
:global .table > tbody > tr > th.danger,
:global .table > tbody > tr.danger > td,
:global .table > tbody > tr.danger > th,
:global .table > tfoot > tr > td.danger,
:global .table > tfoot > tr > th.danger,
:global .table > tfoot > tr.danger > td,
:global .table > tfoot > tr.danger > th {
  background-color: #fbfbfb;
}
:global .table-hover > tbody > tr > td.danger:hover,
:global .table-hover > tbody > tr > th.danger:hover, :global .table-hover > tbody > tr.danger:hover > td, :global .table-hover > tbody > tr.danger:hover > th {
  background-color: #eeeeee;
}
:global .table > thead > tr > td.warning,
:global .table > thead > tr > th.warning, :global .table > thead > tr.warning > td, :global .table > thead > tr.warning > th,
:global .table > tbody > tr > td.warning,
:global .table > tbody > tr > th.warning,
:global .table > tbody > tr.warning > td,
:global .table > tbody > tr.warning > th,
:global .table > tfoot > tr > td.warning,
:global .table > tfoot > tr > th.warning,
:global .table > tfoot > tr.warning > td,
:global .table > tfoot > tr.warning > th {
  background-color: #f3f3f3;
}
:global .table-hover > tbody > tr > td.warning:hover,
:global .table-hover > tbody > tr > th.warning:hover, :global .table-hover > tbody > tr.warning:hover > td, :global .table-hover > tbody > tr.warning:hover > th {
  background-color: #e6e6e6;
}
@media (max-width: 768px) {
  :global .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    -webkit-overflow-scrolling: touch;
  }
  :global .table-responsive > .table {
    margin-bottom: 0;
  }
  :global .table-responsive > .table > thead > tr > th,
  :global .table-responsive > .table > thead > tr > td,
  :global .table-responsive > .table > tbody > tr > th,
  :global .table-responsive > .table > tbody > tr > td,
  :global .table-responsive > .table > tfoot > tr > th,
  :global .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  :global .table-responsive > .table-bordered {
    border: 0;
  }
  :global .table-responsive > .table-bordered > thead > tr > th:first-child,
  :global .table-responsive > .table-bordered > thead > tr > td:first-child,
  :global .table-responsive > .table-bordered > tbody > tr > th:first-child,
  :global .table-responsive > .table-bordered > tbody > tr > td:first-child,
  :global .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  :global .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  :global .table-responsive > .table-bordered > thead > tr > th:last-child,
  :global .table-responsive > .table-bordered > thead > tr > td:last-child,
  :global .table-responsive > .table-bordered > tbody > tr > th:last-child,
  :global .table-responsive > .table-bordered > tbody > tr > td:last-child,
  :global .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  :global .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  :global .table-responsive > .table-bordered > tbody > tr:last-child > th,
  :global .table-responsive > .table-bordered > tbody > tr:last-child > td,
  :global .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  :global .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
:global fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
:global legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  line-height: inherit;
  color: #222;
  border: 0;
  border-bottom: 1px solid #ddd;
}
:global label {
  display: inline-block;
  margin-bottom: 5px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}
:global input[type=search] {
  box-sizing: border-box;
}
:global input[type=radio],
:global input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px 	; /* IE8-9 */
  line-height: normal;
}
:global input[type=file] {
  display: block;
}
:global select[multiple],
:global select[size] {
  height: auto;
}
:global input[type=file]:focus,
:global input[type=radio]:focus,
:global input[type=checkbox]:focus {
  outline: thin dotted #222;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
:global input[type=number]::-webkit-outer-spin-button, :global input[type=number]::-webkit-inner-spin-button {
  height: auto;
}
:global output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  color: #222;
  vertical-align: middle;
}
:global select,
:global textarea,
:global input[type=text],
:global input[type=password],
:global input[type=datetime],
:global input[type=datetime-local],
:global input[type=date],
:global input[type=month],
:global input[type=time],
:global input[type=week],
:global input[type=number],
:global input[type=email],
:global input[type=url],
:global input[type=search],
:global input[type=tel],
:global input[type=color],
:global .form-control {
  display: block;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.7);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
:global select:focus,
:global textarea:focus,
:global input[type=text]:focus,
:global input[type=password]:focus,
:global input[type=datetime]:focus,
:global input[type=datetime-local]:focus,
:global input[type=date]:focus,
:global input[type=month]:focus,
:global input[type=time]:focus,
:global input[type=week]:focus,
:global input[type=number]:focus,
:global input[type=email]:focus,
:global input[type=url]:focus,
:global input[type=search]:focus,
:global input[type=tel]:focus,
:global input[type=color]:focus,
:global .form-control:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(216, 236, 245, 0.6);
}
:global select:-moz-placeholder,
:global textarea:-moz-placeholder,
:global input[type=text]:-moz-placeholder,
:global input[type=password]:-moz-placeholder,
:global input[type=datetime]:-moz-placeholder,
:global input[type=datetime-local]:-moz-placeholder,
:global input[type=date]:-moz-placeholder,
:global input[type=month]:-moz-placeholder,
:global input[type=time]:-moz-placeholder,
:global input[type=week]:-moz-placeholder,
:global input[type=number]:-moz-placeholder,
:global input[type=email]:-moz-placeholder,
:global input[type=url]:-moz-placeholder,
:global input[type=search]:-moz-placeholder,
:global input[type=tel]:-moz-placeholder,
:global input[type=color]:-moz-placeholder,
:global .form-control:-moz-placeholder {
  color: #888;
}
:global select::-moz-placeholder,
:global textarea::-moz-placeholder,
:global input[type=text]::-moz-placeholder,
:global input[type=password]::-moz-placeholder,
:global input[type=datetime]::-moz-placeholder,
:global input[type=datetime-local]::-moz-placeholder,
:global input[type=date]::-moz-placeholder,
:global input[type=month]::-moz-placeholder,
:global input[type=time]::-moz-placeholder,
:global input[type=week]::-moz-placeholder,
:global input[type=number]::-moz-placeholder,
:global input[type=email]::-moz-placeholder,
:global input[type=url]::-moz-placeholder,
:global input[type=search]::-moz-placeholder,
:global input[type=tel]::-moz-placeholder,
:global input[type=color]::-moz-placeholder,
:global .form-control::-moz-placeholder {
  color: #888;
}
:global select:-ms-input-placeholder,
:global textarea:-ms-input-placeholder,
:global input[type=text]:-ms-input-placeholder,
:global input[type=password]:-ms-input-placeholder,
:global input[type=datetime]:-ms-input-placeholder,
:global input[type=datetime-local]:-ms-input-placeholder,
:global input[type=date]:-ms-input-placeholder,
:global input[type=month]:-ms-input-placeholder,
:global input[type=time]:-ms-input-placeholder,
:global input[type=week]:-ms-input-placeholder,
:global input[type=number]:-ms-input-placeholder,
:global input[type=email]:-ms-input-placeholder,
:global input[type=url]:-ms-input-placeholder,
:global input[type=search]:-ms-input-placeholder,
:global input[type=tel]:-ms-input-placeholder,
:global input[type=color]:-ms-input-placeholder,
:global .form-control:-ms-input-placeholder {
  color: #888;
}
:global select::-webkit-input-placeholder,
:global textarea::-webkit-input-placeholder,
:global input[type=text]::-webkit-input-placeholder,
:global input[type=password]::-webkit-input-placeholder,
:global input[type=datetime]::-webkit-input-placeholder,
:global input[type=datetime-local]::-webkit-input-placeholder,
:global input[type=date]::-webkit-input-placeholder,
:global input[type=month]::-webkit-input-placeholder,
:global input[type=time]::-webkit-input-placeholder,
:global input[type=week]::-webkit-input-placeholder,
:global input[type=number]::-webkit-input-placeholder,
:global input[type=email]::-webkit-input-placeholder,
:global input[type=url]::-webkit-input-placeholder,
:global input[type=search]::-webkit-input-placeholder,
:global input[type=tel]::-webkit-input-placeholder,
:global input[type=color]::-webkit-input-placeholder,
:global .form-control::-webkit-input-placeholder {
  color: #888;
}
:global select[disabled], :global select[readonly], fieldset[disabled] :global select,
:global textarea[disabled],
:global textarea[readonly],
fieldset[disabled] :global textarea,
:global input[type=text][disabled],
:global input[type=text][readonly],
fieldset[disabled] :global input[type=text],
:global input[type=password][disabled],
:global input[type=password][readonly],
fieldset[disabled] :global input[type=password],
:global input[type=datetime][disabled],
:global input[type=datetime][readonly],
fieldset[disabled] :global input[type=datetime],
:global input[type=datetime-local][disabled],
:global input[type=datetime-local][readonly],
fieldset[disabled] :global input[type=datetime-local],
:global input[type=date][disabled],
:global input[type=date][readonly],
fieldset[disabled] :global input[type=date],
:global input[type=month][disabled],
:global input[type=month][readonly],
fieldset[disabled] :global input[type=month],
:global input[type=time][disabled],
:global input[type=time][readonly],
fieldset[disabled] :global input[type=time],
:global input[type=week][disabled],
:global input[type=week][readonly],
fieldset[disabled] :global input[type=week],
:global input[type=number][disabled],
:global input[type=number][readonly],
fieldset[disabled] :global input[type=number],
:global input[type=email][disabled],
:global input[type=email][readonly],
fieldset[disabled] :global input[type=email],
:global input[type=url][disabled],
:global input[type=url][readonly],
fieldset[disabled] :global input[type=url],
:global input[type=search][disabled],
:global input[type=search][readonly],
fieldset[disabled] :global input[type=search],
:global input[type=tel][disabled],
:global input[type=tel][readonly],
fieldset[disabled] :global input[type=tel],
:global input[type=color][disabled],
:global input[type=color][readonly],
fieldset[disabled] :global input[type=color],
:global .form-control[disabled],
:global .form-control[readonly],
fieldset[disabled] :global .form-control {
  cursor: not-allowed;
  background-color: #ddd;
}
:global select.invalid,
:global textarea.invalid,
:global input[type=text].invalid,
:global input[type=password].invalid,
:global input[type=datetime].invalid,
:global input[type=datetime-local].invalid,
:global input[type=date].invalid,
:global input[type=month].invalid,
:global input[type=time].invalid,
:global input[type=week].invalid,
:global input[type=number].invalid,
:global input[type=email].invalid,
:global input[type=url].invalid,
:global input[type=search].invalid,
:global input[type=tel].invalid,
:global input[type=color].invalid,
:global .form-control.invalid {
  border: 1px solid #cc0000;
  color: #cc0000;
}
:global select.valid,
:global textarea.valid,
:global input[type=text].valid,
:global input[type=password].valid,
:global input[type=datetime].valid,
:global input[type=datetime-local].valid,
:global input[type=date].valid,
:global input[type=month].valid,
:global input[type=time].valid,
:global input[type=week].valid,
:global input[type=number].valid,
:global input[type=email].valid,
:global input[type=url].valid,
:global input[type=search].valid,
:global input[type=tel].valid,
:global input[type=color].valid,
:global .form-control.valid {
  border: 1px solid #66a559;
  color: #66a559;
}
:global label,
:global input,
:global button,
:global select,
:global textarea {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}
:global .radio.inline,
:global .checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
:global textarea {
  height: auto;
}
:global .form-group {
  margin-bottom: 15px;
}
:global .radio,
:global .checkbox {
  display: block;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  vertical-align: middle;
}
:global .radio label,
:global .checkbox label {
  display: inline;
  margin-bottom: 0;
  cursor: pointer;
}
:global .radio label.disabled, :global .radio label[disabled],
:global .checkbox label.disabled,
:global .checkbox label[disabled] {
  cursor: not-allowed;
}
:global .radio input[type=radio],
:global .radio-inline input[type=radio],
:global .checkbox input[type=checkbox],
:global .checkbox-inline input[type=checkbox] {
  float: left;
  margin-left: -20px;
}
:global .radio + .radio,
:global .checkbox + .checkbox {
  margin-top: -5px;
}
:global .radio-inline,
:global .checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
}
:global .radio-inline + .radio-inline,
:global .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
:global input[type=radio][disabled], fieldset[disabled] :global input[type=radio],
:global input[type=checkbox][disabled],
fieldset[disabled] :global input[type=checkbox],
:global .radio[disabled],
fieldset[disabled] :global .radio,
:global .radio-inline[disabled],
fieldset[disabled] :global .radio-inline,
:global .checkbox[disabled],
fieldset[disabled] :global .checkbox,
:global .checkbox-inline[disabled],
fieldset[disabled] :global .checkbox-inline {
  cursor: not-allowed;
}
:global .input-sm, :global .input-group-sm > .form-control,
:global .input-group-sm > .input-group-addon,
:global .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
:global select.input-sm, :global .input-group-sm > select.form-control,
:global .input-group-sm > select.input-group-addon,
:global .input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px;
}
:global textarea.input-sm, :global .input-group-sm > textarea.form-control,
:global .input-group-sm > textarea.input-group-addon,
:global .input-group-sm > .input-group-btn > textarea.btn {
  height: auto;
}
:global .input-lg, :global .input-group-lg > .form-control,
:global .input-group-lg > .input-group-addon,
:global .input-group-lg > .input-group-btn > .btn {
  height: 50px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 2px;
}
:global select.input-lg, :global .input-group-lg > select.form-control,
:global .input-group-lg > select.input-group-addon,
:global .input-group-lg > .input-group-btn > select.btn {
  height: 50px;
  line-height: 50px;
}
:global textarea.input-lg, :global .input-group-lg > textarea.form-control,
:global .input-group-lg > textarea.input-group-addon,
:global .input-group-lg > .input-group-btn > textarea.btn {
  height: auto;
}
:global .has-warning .help-block,
:global .has-warning .control-label,
:global .has-warning .radio,
:global .has-warning .checkbox,
:global .has-warning .radio-inline,
:global .has-warning .checkbox-inline {
  color: #ffc100;
}
:global .has-warning .form-control {
  border-color: #ffc100;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.075);
}
:global .has-warning .form-control:focus {
  border-color: #cc9a00;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.075), 0 0 6px #ffda66;
}
:global .has-warning .input-group-addon {
  color: #ffc100;
  border-color: #ffc100;
  background-color: #f3f3f3;
}
:global .has-error .help-block,
:global .has-error .control-label,
:global .has-error .radio,
:global .has-error .checkbox,
:global .has-error .radio-inline,
:global .has-error .checkbox-inline {
  color: #cc0000;
}
:global .has-error .form-control {
  border-color: #cc0000;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.075);
}
:global .has-error .form-control:focus {
  border-color: #990000;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.075), 0 0 6px #ff3333;
}
:global .has-error .input-group-addon {
  color: #cc0000;
  border-color: #cc0000;
  background-color: #fbfbfb;
}
:global .has-success .help-block,
:global .has-success .control-label,
:global .has-success .radio,
:global .has-success .checkbox,
:global .has-success .radio-inline,
:global .has-success .checkbox-inline {
  color: #66a559;
}
:global .has-success .form-control {
  border-color: #66a559;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.075);
}
:global .has-success .form-control:focus {
  border-color: #528447;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.075), 0 0 6px #a3c99b;
}
:global .has-success .input-group-addon {
  color: #66a559;
  border-color: #66a559;
  background-color: #b8e7c8;
}
:global input[type=search],
:global .form-search,
:global .search-query {
  border-radius: 15px;
}
:global .form-control-static {
  margin-bottom: 0;
}
:global .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #666;
}
@media (min-width: 769px) {
  :global .form-inline .form-group, :global .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  :global .form-inline .form-control, :global .navbar-form .form-control {
    display: inline-block;
  }
  :global .form-inline .with-marg, :global .navbar-form .with-marg {
    margin-left: 5px;
  }
  :global .form-inline .with-marg:first-child, :global .navbar-form .with-marg:first-child {
    margin-left: 0;
  }
  :global .form-inline .radio, :global .navbar-form .radio,
  :global .form-inline .checkbox,
  :global .navbar-form .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }
  :global .form-inline .radio input[type=radio], :global .navbar-form .radio input[type=radio],
  :global .form-inline .checkbox input[type=checkbox],
  :global .navbar-form .checkbox input[type=checkbox] {
    float: none;
    margin-left: 0;
  }
  :global .form-inline.with-marg .radio, :global .with-marg.navbar-form .radio,
  :global .form-inline.with-marg .select,
  :global .with-marg.navbar-form .select,
  :global .form-inline.with-marg .form-group,
  :global .with-marg.navbar-form .form-group,
  :global .form-inline.with-marg .form-control,
  :global .with-marg.navbar-form .form-control {
    margin: 5px;
  }
  :global .form-inline.with-marg .radio:first-child, :global .with-marg.navbar-form .radio:first-child,
  :global .form-inline.with-marg .select:first-child,
  :global .with-marg.navbar-form .select:first-child,
  :global .form-inline.with-marg .form-group:first-child,
  :global .with-marg.navbar-form .form-group:first-child,
  :global .form-inline.with-marg .form-control:first-child,
  :global .with-marg.navbar-form .form-control:first-child {
    margin-left: 0px;
  }
}
@media (min-width: 569px) {
  :global .form-inline-phone .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  :global .form-inline-phone .form-control {
    display: inline-block;
  }
  :global .form-inline-phone .with-marg {
    margin-left: 5px;
  }
  :global .form-inline-phone .with-marg:first-child {
    margin-left: 0;
  }
  :global .form-inline-phone .radio,
  :global .form-inline-phone .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }
  :global .form-inline-phone .radio input[type=radio],
  :global .form-inline-phone .checkbox input[type=checkbox] {
    float: none;
    margin-left: 0;
  }
  :global .form-inline-phone.with-marg .radio,
  :global .form-inline-phone.with-marg .select,
  :global .form-inline-phone.with-marg .form-group,
  :global .form-inline-phone.with-marg .form-control {
    margin: 5px;
  }
  :global .form-inline-phone.with-marg .radio:first-child,
  :global .form-inline-phone.with-marg .select:first-child,
  :global .form-inline-phone.with-marg .form-group:first-child,
  :global .form-inline-phone.with-marg .form-control:first-child {
    margin-left: 0px;
  }
}
:global .form-inline-all .form-group {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
:global .form-inline-all .form-control {
  display: inline-block;
}
:global .form-inline-all .with-marg {
  margin-left: 5px;
}
:global .form-inline-all .with-marg:first-child {
  margin-left: 0;
}
:global .form-inline-all .radio,
:global .form-inline-all .checkbox {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}
:global .form-inline-all .radio input[type=radio],
:global .form-inline-all .checkbox input[type=checkbox] {
  float: none;
  margin-left: 0;
}
:global .form-inline-all.with-marg .radio,
:global .form-inline-all.with-marg .select,
:global .form-inline-all.with-marg .form-group,
:global .form-inline-all.with-marg .form-control {
  margin: 5px;
}
:global .form-inline-all.with-marg .radio:first-child,
:global .form-inline-all.with-marg .select:first-child,
:global .form-inline-all.with-marg .form-group:first-child,
:global .form-inline-all.with-marg .form-control:first-child {
  margin-left: 0px;
}
:global .form-horizontal .control-label,
:global .form-horizontal .radio,
:global .form-horizontal .checkbox,
:global .form-horizontal .radio-inline,
:global .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}
:global .form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}
:global .form-horizontal .form-group:before, :global .form-horizontal .form-group:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .form-horizontal .form-group:after {
  clear: both;
}
:global .form-horizontal .form-control-static {
  padding-top: 7px;
}
:global .form-horizontal.form-horizontal-default .form-group > label:first-child {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 769px) {
  :global .form-horizontal.form-horizontal-default .form-group > label:first-child {
    float: left;
    width: 33.3333333333%;
  }
}
:global .form-horizontal.form-horizontal-default .form-group > *:nth-child(2) {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 769px) {
  :global .form-horizontal.form-horizontal-default .form-group > *:nth-child(2) {
    float: left;
    width: 66.6666666667%;
  }
}
@media (min-width: 769px) {
  :global .form-horizontal .control-label {
    text-align: right;
  }
}
:global select {
  padding-right: 3px !important;
}
:global .field-maxw-xs {
  max-width: 35px;
}
:global .field-maxw-sm {
  max-width: 90px;
}
:global .field-maxw-md {
  max-width: 150px;
}
:global .field-maxw-lg {
  max-width: 210px;
}
:global .field-maxw-xlg {
  max-width: 270px;
}
:global .field-maxw-xxlg {
  max-width: 530px;
}
:global .input-maxw-xs {
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: 100%;
  max-width: 35px;
}
:global .input-maxw-sm {
  width: 100%;
  max-width: 90px;
}
:global .input-maxw-md {
  width: 100%;
  max-width: 150px;
}
:global .input-maxw-lg {
  width: 100%;
  max-width: 210px;
}
:global .input-maxw-xlg {
  width: 100%;
  max-width: 270px;
}
:global .input-maxw-xxlg {
  width: 100%;
  max-width: 530px;
}
:global .input-w-xs {
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: 35px !important;
}
:global .input-w-sm {
  width: 90px !important;
}
:global .input-w-md {
  width: 150px !important;
}
:global .input-w-lg {
  width: 210px !important;
}
:global .input-w-auto {
  width: auto !important;
}
:global .btn {
  display: inline-block;
  margin-bottom: 0;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  box-shadow: 0px 1px 2px #ddd;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 2px;
  user-select: none;
}
:global .btn:focus {
  outline: thin dotted #222;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
:global .btn:hover, :global .btn:focus {
  color: #222;
  text-decoration: none;
}
:global .btn:active, :global .btn.active {
  outline: 0;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(34, 34, 34, 0.125);
}
:global .btn.disabled, :global .btn[disabled], fieldset[disabled] :global .btn {
  cursor: not-allowed;
  text-shadow: none;
  opacity: 0.65;
  box-shadow: none;
}
:global .btn-default {
  color: #222;
  background-color: #f3f3f3;
  border-color: #ddd;
}
:global .btn-default:hover, :global .btn-default:focus, :global .btn-default:active, :global .btn-default.active {
  color: #222;
  background-color: #dfdfdf;
  border-color: #bebebe;
}
.open :global .btn-default.dropdown-toggle {
  color: #222;
  background-color: #dfdfdf;
  border-color: #bebebe;
}
:global .btn-default:active, :global .btn-default.active {
  background-image: none;
}
.open :global .btn-default.dropdown-toggle {
  background-image: none;
}
:global .btn-default.disabled, :global .btn-default.disabled:hover, :global .btn-default.disabled:focus, :global .btn-default.disabled:active, :global .btn-default.disabled.active, :global .btn-default[disabled], :global .btn-default[disabled]:hover, :global .btn-default[disabled]:focus, :global .btn-default[disabled]:active, :global .btn-default[disabled].active, fieldset[disabled] :global .btn-default, fieldset[disabled] :global .btn-default:hover, fieldset[disabled] :global .btn-default:focus, fieldset[disabled] :global .btn-default:active, fieldset[disabled] :global .btn-default.active {
  background-color: #ddd;
  border-color: #ddd;
  color: #888;
}
:global .btn-primary {
  color: #fff;
  background-color: #559b5e;
  border-color: #2a7140;
}
:global .btn-primary:hover, :global .btn-primary:focus, :global .btn-primary:active, :global .btn-primary.active {
  color: #fff;
  background-color: #47814e;
  border-color: #194427;
}
.open :global .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #47814e;
  border-color: #194427;
}
:global .btn-primary:active, :global .btn-primary.active {
  background-image: none;
}
.open :global .btn-primary.dropdown-toggle {
  background-image: none;
}
:global .btn-primary.disabled, :global .btn-primary.disabled:hover, :global .btn-primary.disabled:focus, :global .btn-primary.disabled:active, :global .btn-primary.disabled.active, :global .btn-primary[disabled], :global .btn-primary[disabled]:hover, :global .btn-primary[disabled]:focus, :global .btn-primary[disabled]:active, :global .btn-primary[disabled].active, fieldset[disabled] :global .btn-primary, fieldset[disabled] :global .btn-primary:hover, fieldset[disabled] :global .btn-primary:focus, fieldset[disabled] :global .btn-primary:active, fieldset[disabled] :global .btn-primary.active {
  background-color: #ddd;
  border-color: #ddd;
  color: #888;
}
:global .btn-warning {
  color: #fff;
  background-color: #f07f04;
  border-color: #f07f04;
}
:global .btn-warning:hover, :global .btn-warning:focus, :global .btn-warning:active, :global .btn-warning.active {
  color: #fff;
  background-color: #c86a03;
  border-color: #b45f03;
}
.open :global .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c86a03;
  border-color: #b45f03;
}
:global .btn-warning:active, :global .btn-warning.active {
  background-image: none;
}
.open :global .btn-warning.dropdown-toggle {
  background-image: none;
}
:global .btn-warning.disabled, :global .btn-warning.disabled:hover, :global .btn-warning.disabled:focus, :global .btn-warning.disabled:active, :global .btn-warning.disabled.active, :global .btn-warning[disabled], :global .btn-warning[disabled]:hover, :global .btn-warning[disabled]:focus, :global .btn-warning[disabled]:active, :global .btn-warning[disabled].active, fieldset[disabled] :global .btn-warning, fieldset[disabled] :global .btn-warning:hover, fieldset[disabled] :global .btn-warning:focus, fieldset[disabled] :global .btn-warning:active, fieldset[disabled] :global .btn-warning.active {
  background-color: #ddd;
  border-color: #ddd;
  color: #888;
}
:global .btn-danger {
  color: #fff;
  background-color: #cc0000;
  border-color: #cc0000;
}
:global .btn-danger:hover, :global .btn-danger:focus, :global .btn-danger:active, :global .btn-danger.active {
  color: #fff;
  background-color: #a30000;
  border-color: #8f0000;
}
.open :global .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a30000;
  border-color: #8f0000;
}
:global .btn-danger:active, :global .btn-danger.active {
  background-image: none;
}
.open :global .btn-danger.dropdown-toggle {
  background-image: none;
}
:global .btn-danger.disabled, :global .btn-danger.disabled:hover, :global .btn-danger.disabled:focus, :global .btn-danger.disabled:active, :global .btn-danger.disabled.active, :global .btn-danger[disabled], :global .btn-danger[disabled]:hover, :global .btn-danger[disabled]:focus, :global .btn-danger[disabled]:active, :global .btn-danger[disabled].active, fieldset[disabled] :global .btn-danger, fieldset[disabled] :global .btn-danger:hover, fieldset[disabled] :global .btn-danger:focus, fieldset[disabled] :global .btn-danger:active, fieldset[disabled] :global .btn-danger.active {
  background-color: #ddd;
  border-color: #ddd;
  color: #888;
}
:global .btn-success {
  color: #fff;
  background-color: #66a559;
  border-color: #66a559;
}
:global .btn-success:hover, :global .btn-success:focus, :global .btn-success:active, :global .btn-success.active {
  color: #fff;
  background-color: #568a4b;
  border-color: #4d7d44;
}
.open :global .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #568a4b;
  border-color: #4d7d44;
}
:global .btn-success:active, :global .btn-success.active {
  background-image: none;
}
.open :global .btn-success.dropdown-toggle {
  background-image: none;
}
:global .btn-success.disabled, :global .btn-success.disabled:hover, :global .btn-success.disabled:focus, :global .btn-success.disabled:active, :global .btn-success.disabled.active, :global .btn-success[disabled], :global .btn-success[disabled]:hover, :global .btn-success[disabled]:focus, :global .btn-success[disabled]:active, :global .btn-success[disabled].active, fieldset[disabled] :global .btn-success, fieldset[disabled] :global .btn-success:hover, fieldset[disabled] :global .btn-success:focus, fieldset[disabled] :global .btn-success:active, fieldset[disabled] :global .btn-success.active {
  background-color: #ddd;
  border-color: #ddd;
  color: #888;
}
:global .btn-info {
  color: #fff;
  background-color: #436b93;
  border-color: #436b93;
}
:global .btn-info:hover, :global .btn-info:focus, :global .btn-info:active, :global .btn-info.active {
  color: #fff;
  background-color: #365777;
  border-color: #304c69;
}
.open :global .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #365777;
  border-color: #304c69;
}
:global .btn-info:active, :global .btn-info.active {
  background-image: none;
}
.open :global .btn-info.dropdown-toggle {
  background-image: none;
}
:global .btn-info.disabled, :global .btn-info.disabled:hover, :global .btn-info.disabled:focus, :global .btn-info.disabled:active, :global .btn-info.disabled.active, :global .btn-info[disabled], :global .btn-info[disabled]:hover, :global .btn-info[disabled]:focus, :global .btn-info[disabled]:active, :global .btn-info[disabled].active, fieldset[disabled] :global .btn-info, fieldset[disabled] :global .btn-info:hover, fieldset[disabled] :global .btn-info:focus, fieldset[disabled] :global .btn-info:active, fieldset[disabled] :global .btn-info.active {
  background-color: #ddd;
  border-color: #ddd;
  color: #888;
}
:global .btn-link,
:global .dibs-btn-link {
  color: #436b93;
  cursor: pointer;
  border-radius: 0;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  box-shadow: none;
  text-transform: none;
}
:global .btn-link, :global .btn-link:active, :global .btn-link[disabled], fieldset[disabled] :global .btn-link,
:global .dibs-btn-link,
:global .dibs-btn-link:active,
:global .dibs-btn-link[disabled],
fieldset[disabled] :global .dibs-btn-link {
  background-color: transparent;
  box-shadow: none;
}
:global .btn-link, :global .btn-link:hover, :global .btn-link:focus, :global .btn-link:active,
:global .dibs-btn-link,
:global .dibs-btn-link:hover,
:global .dibs-btn-link:focus,
:global .dibs-btn-link:active {
  border-color: transparent;
}
:global .btn-link:hover, :global .btn-link:focus,
:global .dibs-btn-link:hover,
:global .dibs-btn-link:focus {
  color: #222;
  text-decoration: underline;
  background-color: transparent;
}
:global .btn-link.disabled, :global .btn-link[disabled], fieldset[disabled] :global .btn-link,
:global .dibs-btn-link.disabled,
:global .dibs-btn-link[disabled],
fieldset[disabled] :global .dibs-btn-link {
  color: #888;
  text-decoration: none;
}
:global .btn-link.disabled:hover, :global .btn-link.disabled:focus, :global .btn-link[disabled]:hover, :global .btn-link[disabled]:focus, fieldset[disabled] :global .btn-link:hover, fieldset[disabled] :global .btn-link:focus,
:global .dibs-btn-link.disabled:hover,
:global .dibs-btn-link.disabled:focus,
:global .dibs-btn-link[disabled]:hover,
:global .dibs-btn-link[disabled]:focus,
fieldset[disabled] :global .dibs-btn-link:hover,
fieldset[disabled] :global .dibs-btn-link:focus {
  color: #888;
  text-decoration: none;
}
:global .btn-lg, :global .btn-group-lg > .btn {
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 2px;
}
:global .btn-md {
  padding: 8px 17px;
  font-size: 16px;
  line-height: 1.33;
  border-radius: 2px;
}
:global .btn-sm, :global .btn-group-sm > .btn,
:global .btn-xs,
:global .btn-group-xs > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
:global .btn-xs, :global .btn-group-xs > .btn {
  padding: 2px 5px 0 5px;
  font-size: 11px;
}
:global .btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
:global .btn-block + .btn-block {
  margin-top: 5px;
}
:global input[type=submit].btn-block,
:global input[type=reset].btn-block,
:global input[type=button].btn-block {
  width: 100%;
}
:global .btn-wrap {
  white-space: normal;
}
:global .fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
:global .fade.in {
  opacity: 1;
}
:global .collapse {
  display: none;
}
:global .collapse.in {
  display: block;
}
:global tbody.collapse.in {
  display: table-row-group;
}
:global .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
:global .recessed {
  -webkit-animation: recess 0.7s ease-out;
  animation: recess 0.7s ease-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%;
}
@-webkit-keyframes recess {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 0.6;
  }
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0.6;
  }
}
:global .full {
  animation: full 0.7s ease-out;
  animation-iteration-count: 1;
  -webkit-animation: full 0.7s ease-out;
  -webkit-animation-iteration-count: 1;
  transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%;
}
@-webkit-keyframes full {
  0% {
    transform: scale(0.8, 0.8);
    opacity: 0.6;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
  0% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0.6;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
}
:global .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid #222;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 0 dotted;
}
:global .dropdown {
  position: relative;
}
:global .dropdown-toggle:focus {
  outline: 0;
}
:global .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1060;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(34, 34, 34, 0.175);
  background-clip: padding-box;
}
:global .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
:global .dropdown-menu .divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  background-color: #ddd;
}
:global .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  line-height: 1.5;
  color: #222;
  white-space: nowrap;
}
:global .dropdown-menu > li > a:hover, :global .dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #559b5e;
  background-color: transparent;
}
:global .dropdown-menu > .active > a, :global .dropdown-menu > .active > a:focus {
  color: #559b5e;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  text-decoration: none;
  outline: 0;
  background-color: transparent;
}
:global .dropdown-menu > .active > a:hover {
  background-color: #559b5e;
  color: #fff;
}
:global .dropdown-menu > .disabled > a, :global .dropdown-menu > .disabled > a:hover, :global .dropdown-menu > .disabled > a:focus {
  color: #888;
}
:global .dropdown-menu > .disabled > a:hover, :global .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}
:global .open > .dropdown-menu {
  display: block;
}
:global .open > a {
  outline: 0;
}
:global .dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.5;
  color: #888;
}
:global .dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1050;
}
:global .pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
:global .dropup .caret,
:global .navbar-fixed-bottom .dropdown .caret {
  border-top: 0 dotted;
  border-bottom: 4px solid #222;
  content: "";
}
:global .dropup .dropdown-menu,
:global .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
@media (min-width: 769px) {
  :global .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  :global .navbar .dropdown-menu {
    top: 85%;
  }
  :global .navbar .dropdown-menu:after {
    position: absolute;
    top: -4px;
    left: 14px;
    display: inline-block;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
    border-left: 4px solid transparent;
    content: "";
  }
  :global .navbar .navbar-right .dropdown-menu:after {
    left: auto;
    right: 14px;
  }
}
.btn-default :global .caret {
  border-top-color: #222;
}
.btn-primary :global .caret, .btn-success :global .caret, .btn-warning :global .caret, .btn-danger :global .caret, .btn-info :global .caret {
  border-top-color: #fff;
}
:global .dropup .btn-default .caret {
  border-bottom-color: #222;
}
:global .dropup .btn-primary .caret,
:global .dropup .btn-success .caret,
:global .dropup .btn-warning .caret,
:global .dropup .btn-danger .caret,
:global .dropup .btn-info .caret {
  border-bottom-color: #fff;
}
:global .btn-group,
:global .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
:global .btn-group > .btn,
:global .btn-group-vertical > .btn {
  position: relative;
  float: left;
}
:global .btn-group > .btn:hover, :global .btn-group > .btn:focus, :global .btn-group > .btn:active, :global .btn-group > .btn.active,
:global .btn-group-vertical > .btn:hover,
:global .btn-group-vertical > .btn:focus,
:global .btn-group-vertical > .btn:active,
:global .btn-group-vertical > .btn.active {
  z-index: 2;
}
:global .btn-group > .btn:focus,
:global .btn-group-vertical > .btn:focus {
  outline: none;
}
:global .btn-group .btn + .btn,
:global .btn-group .btn + .btn-group,
:global .btn-group .btn-group + .btn,
:global .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}
:global .btn-toolbar:before, :global .btn-toolbar:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .btn-toolbar:after {
  clear: both;
}
:global .btn-toolbar .btn-group {
  float: left;
}
:global .btn-toolbar > .btn + .btn,
:global .btn-toolbar > .btn + .btn-group,
:global .btn-toolbar > .btn-group + .btn,
:global .btn-toolbar > .btn-group + .btn-group {
  margin-left: 5px;
}
:global .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
:global .btn-group > .btn:first-child {
  margin-left: 0;
}
:global .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
:global .btn-group > .btn:last-child:not(:first-child),
:global .btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
:global .btn-group > .btn-group {
  float: left;
}
:global .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
:global .btn-group > .btn-group:first-child > .btn:last-child,
:global .btn-group > .btn-group:first-child > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
:global .btn-group > .btn-group:last-child > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
:global .btn-group .dropdown-toggle:active,
:global .btn-group.open .dropdown-toggle {
  outline: 0;
}
:global .btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}
:global .btn-group > .btn-lg + .dropdown-toggle, :global .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}
:global .btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(34, 34, 34, 0.125);
}
:global .btn-group.open .dropdown-toggle.btn-link {
  box-shadow: none;
}
:global .btn .caret {
  margin-left: 0;
}
:global .btn-lg .caret, :global .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}
:global .dropup .btn-lg .caret, :global .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}
:global .btn-group-vertical > .btn,
:global .btn-group-vertical > .btn-group {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}
:global .btn-group-vertical > .btn-group:before, :global .btn-group-vertical > .btn-group:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .btn-group-vertical > .btn-group:after {
  clear: both;
}
:global .btn-group-vertical > .btn-group > .btn {
  float: none;
}
:global .btn-group-vertical > .btn + .btn,
:global .btn-group-vertical > .btn + .btn-group,
:global .btn-group-vertical > .btn-group + .btn,
:global .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
:global .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
:global .btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
:global .btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
:global .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
:global .btn-group-vertical > .btn-group:first-child > .btn:last-child,
:global .btn-group-vertical > .btn-group:first-child > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
:global .btn-group-vertical > .btn-group:last-child > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
:global .btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
:global .btn-group-justified .btn {
  float: none;
  display: table-cell;
  width: 1%;
}
:global [data-toggle=buttons] > .btn > input[type=radio],
:global [data-toggle=buttons] > .btn > input[type=checkbox] {
  display: none;
}
:global .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
:global .input-group.col {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
:global .input-group .form-control {
  width: 100%;
  margin-bottom: 0;
}
:global .input-group-addon,
:global .input-group-btn,
:global .input-group .form-control {
  display: table-cell;
}
:global .input-group-addon:not(:first-child):not(:last-child),
:global .input-group-btn:not(:first-child):not(:last-child),
:global .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
:global .input-group-addon,
:global .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
:global .input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1;
  color: #222;
  text-align: center;
  background-color: #ddd;
  border: 1px solid #bbb;
  border-radius: 2px;
}
:global .input-group-addon.input-sm,
:global .input-group-sm > .input-group-addon,
:global .input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}
:global .input-group-addon.input-lg,
:global .input-group-lg > .input-group-addon,
:global .input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 12px 20px;
  font-size: 18px;
  border-radius: 2px;
}
:global .input-group-addon input[type=radio],
:global .input-group-addon input[type=checkbox] {
  margin-top: 0;
}
:global .input-group .form-control:first-child,
:global .input-group-addon:first-child,
:global .input-group-btn:first-child > .btn,
:global .input-group-btn:first-child > .dropdown-toggle,
:global .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
:global .input-group-addon:first-child {
  border-right: 0;
}
:global .input-group .form-control:last-child,
:global .input-group-addon:last-child,
:global .input-group-btn:last-child > .btn,
:global .input-group-btn:last-child > .dropdown-toggle,
:global .input-group-btn:first-child > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
:global .input-group-addon:last-child {
  border-left: 0;
}
:global .input-group-btn {
  position: relative;
  white-space: nowrap;
}
:global .input-group-btn:first-child > .btn {
  margin-right: -1px;
}
:global .input-group-btn:last-child > .btn {
  margin-left: -1px;
}
:global .input-group-btn > .btn {
  position: relative;
}
:global .input-group-btn > .btn + .btn {
  margin-left: -4px;
}
:global .input-group-btn > .btn:hover, :global .input-group-btn > .btn:active {
  z-index: 2;
}
:global .nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
:global .nav:before, :global .nav:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .nav:after {
  clear: both;
}
:global .nav > li {
  position: relative;
  display: block;
}
:global .nav > li > a {
  position: relative;
  display: block;
  padding: 9px;
}
:global .nav > li.disabled > a {
  color: #888;
}
:global .nav > li.disabled > a:hover, :global .nav > li.disabled > a:focus {
  color: #888;
  cursor: not-allowed;
}
:global .nav .open > a, :global .nav .open > a:hover, :global .nav .open > a:focus {
  background-color: #ddd;
  border-color: #436b93;
}
:global .nav .open > a .caret, :global .nav .open > a:hover .caret, :global .nav .open > a:focus .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}
:global .nav .nav-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  background-color: #ddd;
}
:global .nav > li > a > img {
  max-width: none;
}
:global .nav.nav-tabs {
  border-bottom: 1px solid #bbb;
}
:global .nav.nav-tabs > li {
  color: #436b93;
  float: left;
  border-radius: 1px;
  margin-right: 6px;
  margin-bottom: -1px;
  background-color: #bbb;
  transition: background-color 150ms;
}
:global .nav.nav-tabs > li.active {
  background-color: #2a7140;
}
:global .nav.nav-tabs > li.dropdown.open a.dropdown-toggle {
  background-color: #bbb;
  border-color: transparent;
}
:global .nav.nav-tabs > li > a {
  color: #436b93;
  transition: background-color 150ms;
  background-color: #bbb;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  border-radius: 0;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: none;
  padding: 10px 28px;
  margin-bottom: 0px;
  margin-top: 2px;
}
:global .nav.nav-tabs > li > a:hover {
  background-color: #bbb;
}
:global .nav.nav-tabs > li > a .badge,
:global .nav.nav-tabs > li > a .badge-primary {
  color: inherit;
  background-color: transparent;
  margin-left: 11px;
  margin-right: -7px;
}
:global .nav.nav-tabs > li.active .badge,
:global .nav.nav-tabs > li.active .badge-primary {
  color: #fff;
  background-color: #2a7140;
  margin-right: 0;
}
:global .nav.nav-tabs > li.active > a {
  background-color: #fff;
  border-top: none;
  border-bottom: none;
}
:global .nav.nav-tabs > li.active > a, :global .nav.nav-tabs > li.active > a:hover, :global .nav.nav-tabs > li.active > a:focus {
  cursor: default;
  color: #2a7140;
  background-color: #fff;
  border: 1px solid #bbb;
  border-bottom: none;
  border-top: none;
}
:global .nav.nav-tabs.has-active-sub-tab > li.active .badge,
:global .nav.nav-tabs.has-active-sub-tab > li.active .badge-primary {
  color: inherit;
  background-color: inherit;
  margin-right: -7px;
}
:global .nav.nav-tabs-md > li > a {
  padding: 7px 15px;
}
:global .nav.nav-sub-tabs {
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  border-radius: 0;
  font-size: 12px;
  font-weight: 300;
  top: -1px;
  position: relative;
  padding-top: 4px;
}
:global .nav.nav-sub-tabs > li {
  float: left;
}
:global .nav.nav-sub-tabs > li:first-child a {
  border-left: none;
}
:global .nav.nav-sub-tabs > li:hover > a {
  color: #2a7140;
}
:global .nav.nav-sub-tabs > li > a {
  transition: color 200ms;
  color: #436b93;
  position: relative;
  padding: 5px 20px;
  margin-right: 0;
  margin-bottom: 1px;
  background-color: inherit;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  border-radius: 0;
  border: none;
  border-left: 1px solid #bbb;
  line-height: 1.5em;
}
:global .nav.nav-sub-tabs > li > a .badge,
:global .nav.nav-sub-tabs > li > a .badge-primary {
  color: inherit;
  background-color: transparent;
  margin-left: 11px;
  margin-right: -7px;
  color: inherit;
  background-color: transparent;
  margin-left: 11px;
  margin-right: -7px;
}
:global .nav.nav-sub-tabs > li > a:after {
  content: "";
  position: absolute;
  margin-left: -16px;
  display: block;
  border-top: 8px solid #2a7140;
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
  left: 50%;
  bottom: 0px;
  opacity: 0;
  transition: opacity 20ms;
}
:global .nav.nav-sub-tabs > li.active {
  border-top-color: #2a7140;
}
:global .nav.nav-sub-tabs > li.active .badge,
:global .nav.nav-sub-tabs > li.active .badge-primary {
  color: white;
  background-color: #2a7140;
  margin-right: 0;
}
:global .nav.nav-sub-tabs > li.active > a {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  background-color: #fff;
  border-top: none;
  border-bottom: none;
  color: #2a7140;
}
:global .nav.nav-sub-tabs > li.active > a, :global .nav.nav-sub-tabs > li.active > a:hover, :global .nav.nav-sub-tabs > li.active > a:focus {
  border-top: none;
  border-bottom: none;
  border-right: none;
  color: #2a7140;
}
:global .nav.nav-sub-tabs > li.active > a:after {
  bottom: -10px;
  opacity: 1;
}
@media (min-width: 769px) and (max-width: 1024px) {
  :global .nav.nav-tabs,
  :global .nav.nav-sub-tabs {
    display: flex;
  }
  :global .nav.nav-tabs:before, :global .nav.nav-tabs:after,
  :global .nav.nav-sub-tabs:before,
  :global .nav.nav-sub-tabs:after {
    display: none;
  }
  :global .nav.nav-tabs > li,
  :global .nav.nav-sub-tabs > li {
    flex: 1, 1, auto;
  }
  :global .nav.nav-tabs > li:last-child,
  :global .nav.nav-sub-tabs > li:last-child {
    margin-right: 0;
  }
  :global .nav.nav-tabs > li > a,
  :global .nav.nav-sub-tabs > li > a {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 18px;
  }
  :global .nav.nav-tabs > li > a .badge,
  :global .nav.nav-tabs > li > a .badge-primary,
  :global .nav.nav-sub-tabs > li > a .badge,
  :global .nav.nav-sub-tabs > li > a .badge-primary {
    float: right;
    margin-left: 0;
  }
  :global .nav.nav-tabs .bucket-filter,
  :global .nav.nav-sub-tabs .bucket-filter {
    width: 100%;
  }
}
@media (max-width: 768px) {
  :global .nav.nav-tabs,
  :global .nav.nav-sub-tabs {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }
  :global .nav.nav-tabs > li,
  :global .nav.nav-sub-tabs > li {
    flex: 0, 0, auto;
    margin-right: 0;
    margin-bottom: 1px;
  }
  :global .nav.nav-tabs > li > a,
  :global .nav.nav-sub-tabs > li > a {
    border: none;
  }
  :global .nav.nav-tabs > li > a:focus,
  :global .nav.nav-sub-tabs > li > a:focus {
    outline: none;
  }
  :global .nav.nav-tabs > li > a:after,
  :global .nav.nav-sub-tabs > li > a:after {
    display: none;
  }
  :global .nav.nav-tabs > li.active > a, :global .nav.nav-tabs > li.active a:hover,
  :global .nav.nav-sub-tabs > li.active > a,
  :global .nav.nav-sub-tabs > li.active a:hover {
    border: none;
  }
}
@media (max-width: 568px) {
  :global .nav.nav-tabs > li > a,
  :global .nav.nav-sub-tabs > li > a {
    height: 38px;
    line-height: 18px;
  }
}
@media (max-width: 768px) {
  :global .two-level-bucket-view > .nav.nav-sub-tabs {
    display: none;
  }
}
@media (max-width: 768px) {
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs {
    display: none;
  }
}
@media (max-width: 768px) {
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs {
    padding-top: 0;
    border-top: none;
    background-color: #fff;
  }
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li {
    margin-bottom: 0;
    border-top: 1px solid #bbb;
  }
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li > a {
    margin-bottom: 0;
  }
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li > a .bucket-filter-count {
    background-color: #fff;
    color: #2a7140;
  }
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li.active > a .bucket-filter-count {
    background-color: #2a7140;
    color: #fff;
  }
}
@media (max-width: 568px) {
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li > a {
    height: 28px;
  }
}
@media (max-width: 768px) {
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs .zero-badge-count-bucket {
    display: none;
  }
}
:global .nav-pills > li {
  float: left;
}
:global .nav-pills > li > a {
  border-radius: 2px;
}
:global .nav-pills > li + li {
  margin-left: 2px;
}
:global .nav-pills > li.active > a, :global .nav-pills > li.active > a:hover, :global .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #559b5e;
}
:global .nav-pills > li.active > a .caret, :global .nav-pills > li.active > a:hover .caret, :global .nav-pills > li.active > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
:global .nav-stacked > li {
  float: none;
}
:global .nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}
:global .nav-justified, :global .nav.nav-tabs.nav-justified {
  width: 100%;
}
:global .nav-justified > li, :global .nav.nav-tabs.nav-justified > li {
  float: none;
}
:global .nav-justified > li > a, :global .nav.nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}
:global .nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 769px) {
  :global .nav-justified > li, :global .nav.nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  :global .nav-justified > li > a, :global .nav.nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}
:global .nav-tabs-justified, :global .nav.nav-tabs.nav-justified {
  border-bottom: 0;
}
:global .nav-tabs-justified > li > a, :global .nav.nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 2px;
}
:global .nav-tabs-justified > .active > a, :global .nav.nav-tabs.nav-justified > .active > a,
:global .nav-tabs-justified > .active > a:hover,
:global .nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}
@media (min-width: 769px) {
  :global .nav-tabs-justified > li > a, :global .nav.nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 2px 2px 0 0;
  }
  :global .nav-tabs-justified > .active > a, :global .nav.nav-tabs.nav-justified > .active > a,
  :global .nav-tabs-justified > .active > a:hover,
  :global .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}
:global .tab-content > .tab-pane {
  display: none;
}
:global .tab-content > .active {
  display: block;
}
:global .nav .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}
:global .nav a:hover .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}
:global .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
:global .nav-list {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 0;
}
:global .nav-list > li > a {
  padding: 5px 10px;
  margin-right: -10px;
  margin-left: -10px;
}
:global .nav-header {
  margin-right: -10px;
  margin-left: -10px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  padding: 5px 10px;
  color: #888;
}
:global .navbar {
  position: relative;
  min-height: 61px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
:global .navbar:before, :global .navbar:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .navbar:after {
  clear: both;
}
@media (min-width: 769px) {
  :global .navbar {
    border-radius: 2px;
  }
}
:global .navbar-header:before, :global .navbar-header:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .navbar-header:after {
  clear: both;
}
@media (min-width: 769px) {
  :global .navbar-header {
    float: left;
  }
}
:global .navbar-collapse {
  max-height: 450px;
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
}
:global .navbar-collapse:before, :global .navbar-collapse:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .navbar-collapse:after {
  clear: both;
}
:global .navbar-collapse.in {
  overflow-y: auto;
}
@media (min-width: 769px) {
  :global .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  :global .navbar-collapse.collapse {
    display: flex !important;
    align-items: center;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  :global .navbar-collapse.in {
    overflow-y: auto;
  }
  :global .navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: -15px;
  }
  :global .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-right: -15px;
  }
  :global .navbar-collapse .navbar-text:last-child {
    margin-right: 0;
  }
}
:global .container > .navbar-header,
:global .container > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 769px) {
  :global .container > .navbar-header,
  :global .container > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
:global .navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}
@media (min-width: 769px) {
  :global .navbar-static-top {
    border-radius: 0;
  }
}
:global .navbar-fixed-top,
:global .navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1020;
}
@media (min-width: 769px) {
  :global .navbar-fixed-top,
  :global .navbar-fixed-bottom {
    border-radius: 0;
  }
}
:global .navbar-fixed-top {
  top: 0;
  border-width: 3px 0 1px 0;
}
@media (max-width: 768px) {
  :global .navbar-fixed-top #dibsy-navbar-collapse {
    width: 100%;
  }
}
:global .navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}
:global .navbar-brand {
  float: left;
  padding: 20.5px 0;
  font-size: 18px;
  line-height: 20px;
}
:global .navbar-brand:hover, :global .navbar-brand:focus {
  text-decoration: none;
}
@media (min-width: 769px) {
  .navbar > .container :global .navbar-brand {
    margin-left: -15px;
  }
}
:global .navbar-toggle {
  cursor: pointer;
  outline: none;
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 13.5px;
  margin-bottom: 13.5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
}
:global .navbar-toggle:hover .icon-bar {
  background-color: #559b5e !important;
}
:global .navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
:global .navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}
@media (min-width: 769px) {
  :global .navbar-toggle {
    display: none;
  }
}
:global .navbar-nav {
  margin: 10.25px -15px;
}
:global .navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}
:global .navbar-nav .navbar-badge {
  text-transform: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
}
@media (max-width: 768px) {
  :global .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  :global .navbar-nav .open .dropdown-menu > li > a,
  :global .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  :global .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  :global .navbar-nav .open .dropdown-menu > li > a:hover, :global .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}
@media (min-width: 769px) {
  :global .navbar-nav {
    float: left;
    margin: 0;
  }
  :global .navbar-nav > li {
    float: left;
  }
  :global .navbar-nav > li > a {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
  }
}
:global .navbar-nav.navbar-no-collapse {
  float: left;
  margin: 0;
}
:global .navbar-nav.navbar-no-collapse > li {
  float: left;
}
:global .navbar-nav.navbar-no-collapse > li > a {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}
@media (min-width: 769px) {
  :global .navbar-left {
    float: left !important;
  }
  :global .navbar-right {
    float: right !important;
  }
}
:global .navbar-always-left {
  float: left !important;
}
:global .navbar-always-right {
  float: right !important;
}
:global .navbar-form {
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  margin-top: 14px;
  margin-bottom: 14px;
}
@media (max-width: 568px) {
  :global .navbar-form .form-group {
    margin-bottom: 5px;
  }
}
@media (min-width: 769px) {
  :global .navbar-form {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
  }
}
:global .navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
:global .navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
:global .navbar-nav.pull-right > li > .dropdown-menu,
:global .navbar-nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
:global .navbar-btn {
  margin-top: 14px;
  margin-bottom: 14px;
}
:global .navbar-text {
  float: left;
  margin-top: 20.5px;
  margin-bottom: 20.5px;
}
@media (min-width: 769px) {
  :global .navbar-text {
    margin-left: 15px;
    margin-right: 15px;
  }
}
:global .navbar-default {
  background-color: #fbfbfb;
  border-color: #f3f3f3;
}
:global .navbar-default .navbar-brand {
  color: #888;
}
:global .navbar-default .navbar-brand:hover, :global .navbar-default .navbar-brand:focus {
  color: #666;
  background-color: transparent;
}
:global .navbar-default .navbar-text {
  color: #888;
}
:global .navbar-default .navbar-nav > li > a {
  color: #888;
}
:global .navbar-default .navbar-nav > li > a:hover, :global .navbar-default .navbar-nav > li > a:focus {
  color: #222;
  background-color: transparent;
}
:global .navbar-default .navbar-nav > .active > a, :global .navbar-default .navbar-nav > .active > a:hover, :global .navbar-default .navbar-nav > .active > a:focus {
  color: #666;
  background-color: #f3f3f3;
}
:global .navbar-default .navbar-nav > .disabled > a, :global .navbar-default .navbar-nav > .disabled > a:hover, :global .navbar-default .navbar-nav > .disabled > a:focus {
  color: #bbb;
  background-color: transparent;
}
:global .navbar-default .navbar-toggle {
  border-color: #ddd;
}
:global .navbar-default .navbar-toggle:hover, :global .navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}
:global .navbar-default .navbar-toggle .icon-bar {
  background-color: #bbb;
}
:global .navbar-default .navbar-collapse,
:global .navbar-default .navbar-form {
  border-color: #f3f3f3;
}
:global .navbar-default .navbar-nav > .dropdown > a:hover .caret,
:global .navbar-default .navbar-nav > .dropdown > a:focus .caret {
  border-top-color: #222;
  border-bottom-color: #222;
}
:global .navbar-default .navbar-nav > .open > a, :global .navbar-default .navbar-nav > .open > a:hover, :global .navbar-default .navbar-nav > .open > a:focus {
  background-color: #f3f3f3;
  color: #666;
}
:global .navbar-default .navbar-nav > .open > a .caret, :global .navbar-default .navbar-nav > .open > a:hover .caret, :global .navbar-default .navbar-nav > .open > a:focus .caret {
  border-top-color: #666;
  border-bottom-color: #666;
}
:global .navbar-default .navbar-nav > .dropdown > a .caret {
  border-top-color: #888;
  border-bottom-color: #888;
}
@media (max-width: 768px) {
  :global .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #888;
  }
  :global .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, :global .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222;
    background-color: transparent;
  }
  :global .navbar-default .navbar-nav .open .dropdown-menu > .active > a, :global .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, :global .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #666;
    background-color: #f3f3f3;
  }
  :global .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, :global .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, :global .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #bbb;
    background-color: transparent;
  }
}
:global .navbar-default .navbar-link {
  color: #888;
}
:global .navbar-default .navbar-link:hover {
  color: #222;
}
:global .navbar-inverse {
  background-color: #222;
  border-color: #559b5e;
}
:global .navbar-inverse .navbar-brand {
  color: #fff;
}
:global .navbar-inverse .navbar-brand:hover, :global .navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: transparent;
}
:global .navbar-inverse .navbar-text {
  color: #ddd;
}
:global .navbar-inverse .navbar-nav > li > a {
  color: #fff;
}
:global .navbar-inverse .navbar-nav > li > a:hover, :global .navbar-inverse .navbar-nav > li > a:focus {
  color: #559b5e;
  background-color: transparent;
}
:global .navbar-inverse .navbar-nav > .active > a, :global .navbar-inverse .navbar-nav > .active > a:hover, :global .navbar-inverse .navbar-nav > .active > a:focus {
  color: #559b5e;
  background-color: transparent;
}
:global .navbar-inverse .navbar-nav > .disabled > a, :global .navbar-inverse .navbar-nav > .disabled > a:hover, :global .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #666;
  background-color: transparent;
}
:global .navbar-inverse .navbar-toggle {
  border-color: #222;
}
:global .navbar-inverse .navbar-toggle:hover, :global .navbar-inverse .navbar-toggle:focus {
  background-color: #222;
}
:global .navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}
:global .navbar-inverse .navbar-collapse,
:global .navbar-inverse .navbar-form {
  border-color: #222;
}
:global .navbar-inverse .navbar-nav > .open > a, :global .navbar-inverse .navbar-nav > .open > a:hover, :global .navbar-inverse .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #559b5e;
}
:global .navbar-inverse .navbar-nav > .dropdown > a:hover .caret {
  border-top-color: #559b5e;
  border-bottom-color: #559b5e;
}
:global .navbar-inverse .navbar-nav > .dropdown > a .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
:global .navbar-inverse .navbar-nav > .open > a .caret, :global .navbar-inverse .navbar-nav > .open > a:hover .caret, :global .navbar-inverse .navbar-nav > .open > a:focus .caret {
  border-top-color: #559b5e;
  border-bottom-color: #559b5e;
}
:global .navbar-inverse .navbar-nav.user-label, :global .navbar-inverse .navbar-nav.masquerade-label {
  padding-left: 0px;
  display: inline-block;
  float: right;
  margin-left: -15px;
}
:global .navbar-inverse .navbar-nav .user-link {
  float: right;
  max-width: inherit;
  padding-left: 0px;
  text-align: right;
}
:global .navbar-inverse .navbar-nav .user-link #company-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: -6px;
  max-width: 140px;
}
:global .navbar-inverse .navbar-nav .dropdown-menu > li.dropdown-user-info > a:hover,
:global .navbar-inverse .navbar-nav .dropdown-menu > li.dropdown-user-info > a:focus {
  color: #000;
  cursor: default;
}
@media (min-width: 769px) and (max-width: 1024px) {
  :global .navbar-inverse .navbar-nav.masquerade-label, :global .navbar-inverse .navbar-nav.user-label {
    max-width: 230px;
  }
  :global .navbar-inverse .navbar-nav #user-name,
  :global .navbar-inverse .navbar-nav #user-name-short {
    display: none !important;
  }
  :global .navbar-inverse .navbar-nav #company-name {
    max-width: 150px;
  }
}
@media (min-width: 569px) and (max-width: 1024px) {
  :global .navbar-inverse .navbar-nav #user-name,
  :global .navbar-inverse .navbar-nav #user-name-short,
  :global .navbar-inverse .navbar-nav #company-name,
  :global .navbar-inverse .navbar-nav .masquerade-wrapper {
    display: none !important;
  }
}
@media (min-width: 769px) {
  :global .navbar-inverse .navbar-nav .dropdown-user-info {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  :global .navbar-inverse .navbar-nav.masquerade-label > .dropdown {
    max-width: 200px;
  }
}
@media (max-width: 568px) {
  :global .navbar-inverse .navbar-nav #company-name {
    display: none !important;
  }
  :global .navbar-inverse .navbar-nav.user-label > .dropdown, :global .navbar-inverse .navbar-nav.masquerade-label > .dropdown {
    max-width: 140px;
  }
  :global .navbar-inverse .navbar-nav .dropdown-menu > .dropdown-user-info > a.use-ellipsis {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
  }
}
@media (min-width: 569px) and (max-width: 768px) {
  :global .navbar-inverse .navbar-nav.masquerade-label, :global .navbar-inverse .navbar-nav.user-label {
    max-width: 260px;
  }
  :global .navbar-inverse .navbar-nav #company-name {
    max-width: 180px;
  }
}
@media (max-width: 568px) {
  :global .navbar-inverse .navbar-nav.masquerade-label, :global .navbar-inverse .navbar-nav.user-label {
    max-width: 200px;
  }
  :global .navbar-inverse .navbar-nav #user-name,
  :global .navbar-inverse .navbar-nav #user-name-short {
    display: none !important;
  }
  :global .navbar-inverse .navbar-nav #company-name {
    max-width: 120px;
  }
}
@media (max-width: 768px) {
  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #559b5e;
  }
  :global .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-weight: 300;
  }
  :global .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, :global .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #559b5e;
    background-color: transparent;
  }
  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, :global .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, :global .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #559b5e;
    background-color: transparent;
  }
  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, :global .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, :global .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #666;
    background-color: transparent;
  }
  :global .navbar-inverse .navbar-nav.user-label {
    max-width: initial;
  }
  :global .navbar-inverse .navbar-nav .open li.dropdown-user-info > a:hover,
  :global .navbar-inverse .navbar-nav .open li.dropdown-user-info > a:focus {
    color: #fff;
    cursor: default;
  }
}
:global .navbar-inverse .navbar-link {
  color: #fff;
}
:global .navbar-inverse .navbar-link:hover {
  color: #559b5e;
}
:global .breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f3f3f3;
  border-radius: 2px;
}
:global .breadcrumb > li {
  display: inline-block;
}
:global .breadcrumb > li + li:before {
  content: "/ ";
  padding: 0 5px;
  color: #bbb;
}
:global .breadcrumb > .active {
  color: #888;
}
:global .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 2px;
}
:global .pagination > li {
  display: inline;
}
:global .pagination > li > a,
:global .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
:global .pagination > li:first-child > a,
:global .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
:global .pagination > li:last-child > a,
:global .pagination > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
:global .pagination > li > a:hover, :global .pagination > li > a:focus,
:global .pagination > li > span:hover,
:global .pagination > li > span:focus {
  background-color: #ddd;
}
:global .pagination > .active > a, :global .pagination > .active > a:hover, :global .pagination > .active > a:focus,
:global .pagination > .active > span,
:global .pagination > .active > span:hover,
:global .pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  background-color: #559b5e;
  border-color: #559b5e;
  cursor: default;
}
:global .pagination > .disabled > span,
:global .pagination > .disabled > span:hover,
:global .pagination > .disabled > span:focus,
:global .pagination > .disabled > a,
:global .pagination > .disabled > a:hover,
:global .pagination > .disabled > a:focus {
  color: #888;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
:global .pagination-lg > li > a,
:global .pagination-lg > li > span {
  padding: 12px 20px;
  font-size: 18px;
}
:global .pagination-lg > li:first-child > a,
:global .pagination-lg > li:first-child > span {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
:global .pagination-lg > li:last-child > a,
:global .pagination-lg > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
:global .pagination-sm > li > a,
:global .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
}
:global .pagination-sm > li:first-child > a,
:global .pagination-sm > li:first-child > span {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
:global .pagination-sm > li:last-child > a,
:global .pagination-sm > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
:global .pager {
  padding-left: 0;
  margin: 20px 0;
  list-style: none;
  text-align: center;
}
:global .pager:before, :global .pager:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .pager:after {
  clear: both;
}
:global .pager li {
  display: inline;
}
:global .pager li > a,
:global .pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
:global .pager li > a:hover,
:global .pager li > a:focus {
  text-decoration: none;
  background-color: #ddd;
}
:global .pager .next > a,
:global .pager .next > span {
  float: right;
}
:global .pager .previous > a,
:global .pager .previous > span {
  float: left;
}
:global .pager .disabled > a,
:global .pager .disabled > a:hover,
:global .pager .disabled > a:focus,
:global .pager .disabled > span {
  color: #888;
  background-color: #fff;
  cursor: not-allowed;
}
:global .label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
:global .label[href]:hover, :global .label[href]:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
:global .label:empty {
  display: none;
}
:global .label-default {
  background-color: #888;
}
:global .label-default[href]:hover, :global .label-default[href]:focus {
  background-color: #6f6f6f;
}
:global .label-primary {
  background-color: #559b5e;
}
:global .label-primary[href]:hover, :global .label-primary[href]:focus {
  background-color: #437a4a;
}
:global .label-success {
  background-color: #66a559;
}
:global .label-success[href]:hover, :global .label-success[href]:focus {
  background-color: #528447;
}
:global .label-info {
  background-color: #436b93;
}
:global .label-info[href]:hover, :global .label-info[href]:focus {
  background-color: #335270;
}
:global .label-warning {
  background-color: #f07f04;
}
:global .label-warning[href]:hover, :global .label-warning[href]:focus {
  background-color: #be6403;
}
:global .label-danger {
  background-color: #cc0000;
}
:global .label-danger[href]:hover, :global .label-danger[href]:focus {
  background-color: #990000;
}
:global .badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #888;
  border-radius: 10px;
}
:global .badge:empty {
  display: none;
}
:global a.badge:hover, :global a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
:global .btn .badge {
  position: relative;
  top: -1px;
}
:global a.list-group-item.active > .badge,
:global .nav-pills > .active > a > .badge {
  color: #436b93;
  background-color: #fff;
}
:global .nav-pills > li > a > .badge {
  margin-left: 3px;
}
:global .badge-primary {
  background-color: #559b5e;
}
:global .badge-success {
  background-color: #66a559;
}
:global .badge-info {
  background-color: #436b93;
}
:global .badge-warning {
  background-color: #f07f04;
}
:global .badge-danger {
  background-color: #cc0000;
}
:global .badge-active {
  background-color: #fff;
  color: #436b93;
}
:global .thumbnail {
  display: block;
  margin-bottom: 20px;
}
:global .thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
:global .thumbnail .caption {
  padding: 9px;
  color: #222;
}
:global a.thumbnail:hover,
:global a.thumbnail:focus,
:global a.thumbnail.active {
  border-color: #436b93;
}
:global .alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 2px;
}
:global .alert h4 {
  margin-top: 0;
  color: inherit;
}
:global .alert .alert-link {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}
:global .alert > p,
:global .alert > ul {
  margin-bottom: 0;
}
:global .alert > p + p {
  margin-top: 5px;
}
:global .alert-dismissable {
  padding-right: 35px;
}
:global .alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
:global .alert-success {
  background-color: #b8e7c8;
  border-color: #66a559;
  color: #66a559;
}
:global .alert-success hr {
  border-top-color: #5c9450;
}
:global .alert-success .alert-link {
  color: #528447;
}
:global .alert-info {
  background-color: #d8ecf5;
  border-color: #d8ecf5;
  color: #436b93;
}
:global .alert-info hr {
  border-top-color: #c4e2f0;
}
:global .alert-info .alert-link {
  color: #335270;
}
:global .alert-warning {
  background-color: #f3f3f3;
  border-color: #ffc100;
  color: #ffc100;
}
:global .alert-warning hr {
  border-top-color: #e6ae00;
}
:global .alert-warning .alert-link {
  color: #cc9a00;
}
:global .alert-todo {
  background-color: #f3f3f3;
  border-color: #cc0000;
  color: #222;
}
:global .alert-todo hr {
  border-top-color: #b30000;
}
:global .alert-todo .alert-link {
  color: #090909;
}
:global .alert-danger,
:global .alert-error {
  background-color: #fbfbfb;
  border-color: #cc0000;
  color: #cc0000;
}
:global .alert-danger hr,
:global .alert-error hr {
  border-top-color: #b30000;
}
:global .alert-danger .alert-link,
:global .alert-error .alert-link {
  color: #990000;
}
:global .alert-inverse {
  background-color: #222;
  border-color: #888;
  color: #ddd;
}
:global .alert-inverse hr {
  border-top-color: #7b7b7b;
}
:global .alert-inverse .alert-link {
  color: #c4c4c4;
}
:global #dibs-nav {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1020;
}
:global #dibs-nav .page-message {
  position: relative;
  top: 65px;
  width: 700px;
  box-shadow: 0px 2px 3px #ddd;
}
:global .page-message {
  margin: 0 auto;
  max-width: 80%;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
:global .progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f3f3f3;
  border-radius: 2px;
  box-shadow: inset 0 1px 2px rgba(34, 34, 34, 0.1);
}
:global .progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #559b5e;
  box-shadow: inset 0 -1px 0 rgba(34, 34, 34, 0.15);
  transition: width 0.6s ease;
}
:global .progress-striped .progress-bar {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
:global .progress.active .progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
}
:global .progress-bar-success {
  background-color: #66a559;
}
.progress-striped :global .progress-bar-success {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
:global .progress-bar-info {
  background-color: #436b93;
}
.progress-striped :global .progress-bar-info {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
:global .progress-bar-warning {
  background-color: #f07f04;
}
.progress-striped :global .progress-bar-warning {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
:global .progress-bar-danger {
  background-color: #cc0000;
}
.progress-striped :global .progress-bar-danger {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
:global .media,
:global .media-body {
  overflow: hidden;
  zoom: 1;
}
:global .media,
:global .media .media {
  margin-top: 15px;
}
:global .media:first-child {
  margin-top: 0;
}
:global .media-object {
  display: block;
}
:global .media-heading {
  margin: 0 0 5px;
}
:global .media > .pull-left {
  margin-right: 10px;
}
:global .media > .pull-right {
  margin-left: 10px;
}
:global .media-list {
  padding-left: 0;
  list-style: none;
}
:global .list-group {
  margin-bottom: 20px;
  padding-left: 0;
}
:global .list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}
:global .list-group-item:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
:global .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
:global .list-group-item > .badge {
  float: right;
}
:global .list-group-item > .badge + .badge {
  margin-right: 5px;
}
:global a.list-group-item {
  color: #666;
}
:global a.list-group-item .list-group-item-heading {
  color: #222;
}
:global a.list-group-item:hover, :global a.list-group-item:focus {
  text-decoration: none;
  background-color: #f3f3f3;
}
:global a.list-group-item.active, :global a.list-group-item.active:hover, :global a.list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #559b5e;
  border-color: #559b5e;
}
:global a.list-group-item.active .list-group-item-heading, :global a.list-group-item.active:hover .list-group-item-heading, :global a.list-group-item.active:focus .list-group-item-heading {
  color: inherit;
}
:global a.list-group-item.active .list-group-item-text, :global a.list-group-item.active:hover .list-group-item-text, :global a.list-group-item.active:focus .list-group-item-text {
  color: #d4e8d7;
}
:global .list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
:global .list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}
:global .panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(34, 34, 34, 0.05);
}
:global .panel-body {
  padding: 15px;
}
:global .panel-body:before, :global .panel-body:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .panel-body:after {
  clear: both;
}
:global .panel > .list-group {
  margin-bottom: 0;
}
:global .panel > .list-group .list-group-item {
  border-width: 1px 0;
}
:global .panel > .list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
:global .panel > .list-group .list-group-item:last-child {
  border-bottom: 0;
}
:global .panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
:global .panel > .table,
:global .panel > .table-responsive {
  margin-bottom: 0;
}
:global .panel > .panel-body + .table,
:global .panel > .panel-body + .table-responsive {
  border-top: 1px solid #ddd;
}
:global .panel > .table-bordered,
:global .panel > .table-responsive > .table-bordered {
  border: 0;
}
:global .panel > .table-bordered > thead > tr > th:first-child,
:global .panel > .table-bordered > thead > tr > td:first-child,
:global .panel > .table-bordered > tbody > tr > th:first-child,
:global .panel > .table-bordered > tbody > tr > td:first-child,
:global .panel > .table-bordered > tfoot > tr > th:first-child,
:global .panel > .table-bordered > tfoot > tr > td:first-child,
:global .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
:global .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
:global .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
:global .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
:global .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
:global .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
:global .panel > .table-bordered > thead > tr > th:last-child,
:global .panel > .table-bordered > thead > tr > td:last-child,
:global .panel > .table-bordered > tbody > tr > th:last-child,
:global .panel > .table-bordered > tbody > tr > td:last-child,
:global .panel > .table-bordered > tfoot > tr > th:last-child,
:global .panel > .table-bordered > tfoot > tr > td:last-child,
:global .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
:global .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
:global .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
:global .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
:global .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
:global .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
:global .panel > .table-bordered > thead > tr:last-child > th, :global .panel > .table-bordered > thead > tr:last-child > td,
:global .panel > .table-bordered > tbody > tr:last-child > th,
:global .panel > .table-bordered > tbody > tr:last-child > td,
:global .panel > .table-bordered > tfoot > tr:last-child > th,
:global .panel > .table-bordered > tfoot > tr:last-child > td,
:global .panel > .table-responsive > .table-bordered > thead > tr:last-child > th,
:global .panel > .table-responsive > .table-bordered > thead > tr:last-child > td,
:global .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
:global .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
:global .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th,
:global .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}
:global .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
}
:global .panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
:global .panel-heading-sm {
  font-size: 12px;
}
:global .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}
:global .panel-title > a {
  color: inherit;
}
:global .panel-footer {
  padding: 10px 15px;
  background-color: #f3f3f3;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
:global .panel-group .panel {
  margin-bottom: 0;
  border-radius: 2px;
  overflow: hidden;
}
:global .panel-group .panel + .panel {
  margin-top: 5px;
}
:global .panel-group .panel-heading {
  border-bottom: 0;
}
:global .panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #ddd;
}
:global .panel-group .panel-footer {
  border-top: 0;
}
:global .panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}
:global .panel-default {
  border-color: #ddd;
}
:global .panel-default > .panel-heading {
  color: #222;
  background-color: #fff;
  border-color: #ddd;
}
:global .panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #ddd;
}
:global .panel-default > .panel-heading > .dropdown .caret {
  border-color: #222 transparent;
}
:global .panel-default > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #ddd;
}
:global .panel-primary {
  border-color: #559b5e;
}
:global .panel-primary > .panel-heading {
  color: #fff;
  background-color: #559b5e;
  border-color: #559b5e;
}
:global .panel-primary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #559b5e;
}
:global .panel-primary > .panel-heading > .dropdown .caret {
  border-color: #fff transparent;
}
:global .panel-primary > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #559b5e;
}
:global .panel-success {
  border-color: #66a559;
}
:global .panel-success > .panel-heading {
  color: #66a559;
  background-color: #b8e7c8;
  border-color: #66a559;
}
:global .panel-success > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #66a559;
}
:global .panel-success > .panel-heading > .dropdown .caret {
  border-color: #66a559 transparent;
}
:global .panel-success > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #66a559;
}
:global .panel-warning {
  border-color: #ffc100;
}
:global .panel-warning > .panel-heading {
  color: #ffc100;
  background-color: #f3f3f3;
  border-color: #ffc100;
}
:global .panel-warning > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #ffc100;
}
:global .panel-warning > .panel-heading > .dropdown .caret {
  border-color: #ffc100 transparent;
}
:global .panel-warning > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #ffc100;
}
:global .panel-danger {
  border-color: #cc0000;
}
:global .panel-danger > .panel-heading {
  color: #cc0000;
  background-color: #fff;
  border-color: #cc0000;
}
:global .panel-danger > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #cc0000;
}
:global .panel-danger > .panel-heading > .dropdown .caret {
  border-color: #cc0000 transparent;
}
:global .panel-danger > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #cc0000;
}
:global .panel-info {
  border-color: #d8ecf5;
}
:global .panel-info > .panel-heading {
  color: #436b93;
  background-color: #d8ecf5;
  border-color: #d8ecf5;
}
:global .panel-info > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #d8ecf5;
}
:global .panel-info > .panel-heading > .dropdown .caret {
  border-color: #436b93 transparent;
}
:global .panel-info > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #d8ecf5;
}
:global .well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #fbfbfb;
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(34, 34, 34, 0.05);
}
:global .well blockquote {
  border-color: #ddd;
  border-color: rgba(34, 34, 34, 0.15);
}
:global .well .well-heading {
  margin: -19px -19px 20px -19px;
  border-radius: 2px 2px 0 0;
  padding: 19px;
}
:global .well.well-white {
  background-color: #fff;
}
:global .well-heading {
  border-bottom: 1px solid #f3f3f3;
  background-color: #fbfbfb;
}
:global .well-lg {
  padding: 29px;
  border-radius: 2px;
}
:global .well-lg .well-heading {
  margin: -29px -29px 30px -29px;
  border-radius: 2px 2px 0 0;
  padding: 29px;
}
:global .well-sm {
  padding: 14px;
  border-radius: 2px;
}
:global .well-sm .well-heading {
  margin: -14px -14px 15px -14px;
  border-radius: 2px 2px 0 0;
  padding: 14px;
}
:global .close {
  float: right;
  font-size: 20px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  line-height: 1;
  color: #888;
  opacity: 0.2;
}
:global .close:hover, :global .close:focus {
  color: #222;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}
:global button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
:global .flexbox-row {
  display: flex;
  flex-direction: row;
}
:global .flexbox-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
:global .flexbox-column {
  display: flex;
  flex-direction: column;
}
:global .flexbox-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
:global .flex-1 {
  flex: 1 1 0;
}
:global .flex-2 {
  flex: 2 1 0;
}
:global .flex-3 {
  flex: 3 1 0;
}
:global .flex-4 {
  flex: 4 1 0;
}
:global .overflow-y-scroll {
  overflow-y: scroll;
}
:global .modal-open {
  overflow: hidden;
}
:global .modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
:global .modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out;
}
:global .modal.in .modal-dialog {
  transform: translate(0, 0);
}
:global .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
:global .modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
:global .modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #888;
  border-radius: 2px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.7);
  background-clip: padding-box;
  outline: 0;
}
:global .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #222;
}
:global .modal-backdrop.fade {
  opacity: 0;
}
:global .modal-backdrop.in {
  opacity: 0.5;
}
:global .modal-header {
  padding: 9px;
  border-bottom: 1px solid #ddd;
  min-height: 10.5px;
}
:global .modal-header .close {
  margin-top: 4px;
  padding: 3px;
}
:global .modal-title {
  margin: 0;
  line-height: 1.5;
}
:global .modal-body {
  position: relative;
  padding: 20px;
}
:global .modal-footer {
  padding: 20px;
  text-align: right;
  border-top: 1px solid #ddd;
}
:global .modal-footer:before, :global .modal-footer:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .modal-footer:after {
  clear: both;
}
:global .modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
:global .modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
:global .modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
:global .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 769px) {
  :global .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  :global .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
  }
  :global .modal-sm {
    width: 300px;
  }
}
@media (min-width: 769px) {
  :global .modal-lg {
    width: 900px;
  }
}
:global .modal-rail {
  background-color: #fbfbfb;
  margin: -20px -20px 20px -20px;
  padding: 10px 20px;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
}
:global .tooltip {
  position: absolute;
  z-index: 1100;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0;
}
:global .tooltip.in {
  opacity: 0.9;
}
:global .tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
:global .tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
:global .tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
:global .tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
:global .tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #222;
  border-radius: 2px;
}
:global .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
:global .tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #222;
}
:global .tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: #222;
}
:global .tooltip.top-right .tooltip-arrow {
  bottom: 0;
  right: 5px;
  border-width: 5px 5px 0;
  border-top-color: #222;
}
:global .tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #222;
}
:global .tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #222;
}
:global .tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #222;
}
:global .tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #222;
}
:global .tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #222;
}
:global .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  display: none;
  max-width: 500px;
  padding: 1px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bbb;
  border-radius: 2px;
  box-shadow: 0 5px 10px rgba(34, 34, 34, 0.2);
  white-space: normal;
}
:global .popover.top {
  margin-top: -10px;
}
:global .popover.right {
  margin-left: 10px;
}
:global .popover.bottom {
  margin-top: 10px;
}
:global .popover.left {
  margin-left: -10px;
}
:global .popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  line-height: 18px;
  background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
}
:global .popover-content {
  padding: 9px 14px;
}
:global .popover .arrow, :global .popover .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
:global .popover .arrow {
  border-width: 11px;
}
:global .popover .arrow:after {
  border-width: 10px;
  content: "";
}
:global .popover.top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: rgba(34, 34, 34, 0.25);
  bottom: -11px;
}
:global .popover.top .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
:global .popover.right .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: rgba(34, 34, 34, 0.25);
}
:global .popover.right .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}
:global .popover.bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #888;
  border-bottom-color: rgba(34, 34, 34, 0.25);
  top: -11px;
}
:global .popover.bottom .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
:global .popover.left .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #888;
  border-left-color: rgba(34, 34, 34, 0.25);
}
:global .popover.left .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}
:global .carousel {
  position: relative;
}
:global .carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}
:global .carousel-inner > .item {
  display: none;
  position: relative;
  transition: 0.6s ease-in-out left;
}
:global .carousel-inner > .item > img,
:global .carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
  line-height: 1;
}
:global .carousel-inner > .active,
:global .carousel-inner > .next,
:global .carousel-inner > .prev {
  display: block;
}
:global .carousel-inner > .active {
  left: 0;
}
:global .carousel-inner > .next,
:global .carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
:global .carousel-inner > .next {
  left: 100%;
}
:global .carousel-inner > .prev {
  left: -100%;
}
:global .carousel-inner > .next.left,
:global .carousel-inner > .prev.right {
  left: 0;
}
:global .carousel-inner > .active.left {
  left: -100%;
}
:global .carousel-inner > .active.right {
  left: 100%;
}
:global .carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  font-size: 18px;
  color: #fff;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.7);
}
:global .carousel-control.left {
  background-image: linear-gradient(to right, rgba(34, 34, 34, 0.5) 0%, rgba(34, 34, 34, 0.0001) 100%);
  background-repeat: repeat-x;
}
:global .carousel-control.right {
  left: auto;
  right: 0;
  background-image: linear-gradient(to right, rgba(34, 34, 34, 0.0001) 0%, rgba(34, 34, 34, 0.5) 100%);
  background-repeat: repeat-x;
}
:global .carousel-control:hover, :global .carousel-control:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
}
:global .carousel-control .icon-prev,
:global .carousel-control .icon-next,
:global .carousel-control .glyphicon-chevron-left,
:global .carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
:global .carousel-control .icon-prev,
:global .carousel-control .glyphicon-chevron-left {
  left: 50%;
}
:global .carousel-control .icon-next,
:global .carousel-control .glyphicon-chevron-right {
  right: 50%;
}
:global .carousel-control .icon-prev,
:global .carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
}
:global .carousel-control .icon-prev:before {
  content: "‹";
}
:global .carousel-control .icon-next:before {
  content: "›";
}
:global .carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}
:global .carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;
}
:global .carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #fff;
}
:global .carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.7);
}
:global .carousel-caption .btn {
  text-shadow: none;
}
@media (min-width: 769px) {
  :global .carousel-control .glyphicons-chevron-left,
  :global .carousel-control .glyphicons-chevron-right,
  :global .carousel-control .icon-prev,
  :global .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px;
  }
  :global .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }
  :global .carousel-indicators {
    bottom: 20px;
  }
}
:global .clearfix:before, :global .clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .clearfix:after {
  clear: both;
}
:global .center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
:global .pull-right,
:global .right,
:global .fr {
  float: right !important;
}
:global .pull-left,
:global .left,
:global .fl {
  float: left !important;
}
:global .hide {
  display: none !important;
}
:global .show {
  display: block !important;
}
:global .invisible {
  visibility: hidden;
}
:global .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
:global .hidden {
  display: none !important;
  visibility: hidden !important;
}
:global .affix {
  position: fixed;
}
:global .no-spacing {
  margin: 0 !important;
  padding: 0 !important;
}
:global .no-margin {
  margin: 0 !important;
}
:global .no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
:global .no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
:global .no-padd {
  padding: 0 !important;
}
:global .no-side-padd, :global .btn-link-no-spacing {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
:global .no-border {
  border: 0 !important;
}
:global .no-min-width, :global .btn-link-no-spacing {
  min-width: 0 !important;
}
:global .force-inline {
  display: inline-block !important;
}
@-ms-viewport {
  :global {
    width: device-width;
  }
}
@media (max-width: 568px) {
  :global .fullw-below-sm {
    width: 100% !important;
    max-width: 100% !important;
  }
  :global .clear-below-sm {
    clear: both;
  }
}
@media (max-width: 768px) {
  :global .fullw-below-md {
    width: 100% !important;
    max-width: 100% !important;
  }
  :global .clear-below-md {
    clear: both;
  }
}
@media (max-width: 568px) {
  :global .hide-below-xs {
    display: none !important;
  }
  :global .row-below-xs {
    margin-left: -15px;
    margin-right: -15px;
  }
  :global .row-below-xs:before, :global .row-below-xs:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  :global .row-below-xs:after {
    clear: both;
  }
}
@media (max-width: 768px) {
  :global .hide-below-sm {
    display: none !important;
  }
  :global .row-below-sm {
    margin-left: -15px;
    margin-right: -15px;
  }
  :global .row-below-sm:before, :global .row-below-sm:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  :global .row-below-sm:after {
    clear: both;
  }
}
@media (max-width: 1024px) {
  :global .hide-below-md {
    display: none !important;
  }
  :global .row-below-md {
    margin-left: -15px;
    margin-right: -15px;
  }
  :global .row-below-md:before, :global .row-below-md:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  :global .row-below-md:after {
    clear: both;
  }
}
:global .hide-below-lg {
  display: none !important;
}
:global .row-below-lg {
  margin-left: -15px;
  margin-right: -15px;
}
:global .row-below-lg:before, :global .row-below-lg:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
:global .row-below-lg:after {
  clear: both;
}
@media (min-width: 1025px) {
  :global .hide-above-md {
    display: none !important;
  }
  :global .row-above-md {
    margin-left: -15px;
    margin-right: -15px;
  }
  :global .row-above-md:before, :global .row-above-md:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  :global .row-above-md:after {
    clear: both;
  }
}
@media (min-width: 769px) {
  :global .hide-above-sm {
    display: none !important;
  }
  :global .row-above-sm {
    margin-left: -15px;
    margin-right: -15px;
  }
  :global .row-above-sm:before, :global .row-above-sm:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  :global .row-above-sm:after {
    clear: both;
  }
}
@media (min-width: 569px) {
  :global .hide-above-xs {
    display: none !important;
  }
  :global .row-above-xs {
    margin-left: -15px;
    margin-right: -15px;
  }
  :global .row-above-xs:before, :global .row-above-xs:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  :global .row-above-xs:after {
    clear: both;
  }
}
:global .visible-xs,
:global tr.visible-xs,
:global th.visible-xs,
:global td.visible-xs {
  display: none !important;
}
:global .visible-sm,
:global tr.visible-sm,
:global th.visible-sm,
:global td.visible-sm {
  display: none !important;
}
:global .visible-md,
:global tr.visible-md,
:global th.visible-md,
:global td.visible-md {
  display: none !important;
}
:global .visible-lg,
:global tr.visible-lg,
:global th.visible-lg,
:global td.visible-lg {
  display: none !important;
}
:global .hidden-xs {
  display: inherit !important;
}
:global tr.hidden-xs {
  display: table-row !important;
}
:global th.hidden-xs,
:global td.hidden-xs {
  display: table-cell !important;
}
:global .hidden-sm {
  display: inherit !important;
}
:global tr.hidden-sm {
  display: table-row !important;
}
:global th.hidden-sm,
:global td.hidden-sm {
  display: table-cell !important;
}
:global .hidden-md {
  display: inherit !important;
}
:global tr.hidden-md {
  display: table-row !important;
}
:global th.hidden-md,
:global td.hidden-md {
  display: table-cell !important;
}
:global .hidden-lg {
  display: inherit !important;
}
:global tr.hidden-lg {
  display: table-row !important;
}
:global th.hidden-lg,
:global td.hidden-lg {
  display: table-cell !important;
}
@media (max-width: 568px) {
  :global .no-side-padd-xs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  :global .no-side-marg-xs {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  :global .visible-xs {
    display: inherit !important;
  }
  :global tr.visible-xs {
    display: table-row !important;
  }
  :global th.visible-xs,
  :global td.visible-xs {
    display: table-cell !important;
  }
  :global .visible-sm.visible-xs {
    display: inherit !important;
  }
  :global tr.visible-sm.visible-xs {
    display: table-row !important;
  }
  :global th.visible-sm.visible-xs,
  :global td.visible-sm.visible-xs {
    display: table-cell !important;
  }
  :global .visible-md.visible-xs {
    display: inherit !important;
  }
  :global tr.visible-md.visible-xs {
    display: table-row !important;
  }
  :global th.visible-md.visible-xs,
  :global td.visible-md.visible-xs {
    display: table-cell !important;
  }
  :global .visible-lg.visible-xs {
    display: inherit !important;
  }
  :global tr.visible-lg.visible-xs {
    display: table-row !important;
  }
  :global th.visible-lg.visible-xs,
  :global td.visible-lg.visible-xs {
    display: table-cell !important;
  }
  :global .hidden-xs,
  :global tr.hidden-xs,
  :global th.hidden-xs,
  :global td.hidden-xs {
    display: none !important;
  }
  :global .hidden-sm.hidden-xs,
  :global tr.hidden-sm.hidden-xs,
  :global th.hidden-sm.hidden-xs,
  :global td.hidden-sm.hidden-xs {
    display: none !important;
  }
  :global .hidden-md.hidden-xs,
  :global tr.hidden-md.hidden-xs,
  :global th.hidden-md.hidden-xs,
  :global td.hidden-md.hidden-xs {
    display: none !important;
  }
  :global .hidden-lg.hidden-xs,
  :global tr.hidden-lg.hidden-xs,
  :global th.hidden-lg.hidden-xs,
  :global td.hidden-lg.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 569px) and (max-width: 768px) {
  :global .no-side-padd-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  :global .no-side-marg-sm {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  :global .visible-xs.visible-sm {
    display: inherit !important;
  }
  :global tr.visible-xs.visible-sm {
    display: table-row !important;
  }
  :global th.visible-xs.visible-sm,
  :global td.visible-xs.visible-sm {
    display: table-cell !important;
  }
  :global .visible-sm {
    display: inherit !important;
  }
  :global tr.visible-sm {
    display: table-row !important;
  }
  :global th.visible-sm,
  :global td.visible-sm {
    display: table-cell !important;
  }
  :global .visible-md.visible-sm {
    display: inherit !important;
  }
  :global tr.visible-md.visible-sm {
    display: table-row !important;
  }
  :global th.visible-md.visible-sm,
  :global td.visible-md.visible-sm {
    display: table-cell !important;
  }
  :global .visible-lg.visible-sm {
    display: inherit !important;
  }
  :global tr.visible-lg.visible-sm {
    display: table-row !important;
  }
  :global th.visible-lg.visible-sm,
  :global td.visible-lg.visible-sm {
    display: table-cell !important;
  }
  :global .hidden-xs.hidden-sm,
  :global tr.hidden-xs.hidden-sm,
  :global th.hidden-xs.hidden-sm,
  :global td.hidden-xs.hidden-sm {
    display: none !important;
  }
  :global .hidden-sm,
  :global tr.hidden-sm,
  :global th.hidden-sm,
  :global td.hidden-sm {
    display: none !important;
  }
  :global .hidden-md.hidden-sm,
  :global tr.hidden-md.hidden-sm,
  :global th.hidden-md.hidden-sm,
  :global td.hidden-md.hidden-sm {
    display: none !important;
  }
  :global .hidden-lg.hidden-sm,
  :global tr.hidden-lg.hidden-sm,
  :global th.hidden-lg.hidden-sm,
  :global td.hidden-lg.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  :global .no-side-padd-md {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  :global .no-side-marg-md {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  :global .visible-xs.visible-md {
    display: inherit !important;
  }
  :global tr.visible-xs.visible-md {
    display: table-row !important;
  }
  :global th.visible-xs.visible-md,
  :global td.visible-xs.visible-md {
    display: table-cell !important;
  }
  :global .visible-sm.visible-md {
    display: inherit !important;
  }
  :global tr.visible-sm.visible-md {
    display: table-row !important;
  }
  :global th.visible-sm.visible-md,
  :global td.visible-sm.visible-md {
    display: table-cell !important;
  }
  :global .visible-md {
    display: inherit !important;
  }
  :global tr.visible-md {
    display: table-row !important;
  }
  :global th.visible-md,
  :global td.visible-md {
    display: table-cell !important;
  }
  :global .visible-lg.visible-md {
    display: inherit !important;
  }
  :global tr.visible-lg.visible-md {
    display: table-row !important;
  }
  :global th.visible-lg.visible-md,
  :global td.visible-lg.visible-md {
    display: table-cell !important;
  }
  :global .hidden-xs.hidden-md,
  :global tr.hidden-xs.hidden-md,
  :global th.hidden-xs.hidden-md,
  :global td.hidden-xs.hidden-md {
    display: none !important;
  }
  :global .hidden-sm.hidden-md,
  :global tr.hidden-sm.hidden-md,
  :global th.hidden-sm.hidden-md,
  :global td.hidden-sm.hidden-md {
    display: none !important;
  }
  :global .hidden-md,
  :global tr.hidden-md,
  :global th.hidden-md,
  :global td.hidden-md {
    display: none !important;
  }
  :global .hidden-lg.hidden-md,
  :global tr.hidden-lg.hidden-md,
  :global th.hidden-lg.hidden-md,
  :global td.hidden-lg.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  :global .no-side-padd-lg {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  :global .no-side-marg-lg {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  :global .visible-xs.visible-lg {
    display: inherit !important;
  }
  :global tr.visible-xs.visible-lg {
    display: table-row !important;
  }
  :global th.visible-xs.visible-lg,
  :global td.visible-xs.visible-lg {
    display: table-cell !important;
  }
  :global .visible-sm.visible-lg {
    display: inherit !important;
  }
  :global tr.visible-sm.visible-lg {
    display: table-row !important;
  }
  :global th.visible-sm.visible-lg,
  :global td.visible-sm.visible-lg {
    display: table-cell !important;
  }
  :global .visible-md.visible-lg {
    display: inherit !important;
  }
  :global tr.visible-md.visible-lg {
    display: table-row !important;
  }
  :global th.visible-md.visible-lg,
  :global td.visible-md.visible-lg {
    display: table-cell !important;
  }
  :global .visible-lg {
    display: inherit !important;
  }
  :global tr.visible-lg {
    display: table-row !important;
  }
  :global th.visible-lg,
  :global td.visible-lg {
    display: table-cell !important;
  }
  :global .hidden-xs.hidden-lg,
  :global tr.hidden-xs.hidden-lg,
  :global th.hidden-xs.hidden-lg,
  :global td.hidden-xs.hidden-lg {
    display: none !important;
  }
  :global .hidden-sm.hidden-lg,
  :global tr.hidden-sm.hidden-lg,
  :global th.hidden-sm.hidden-lg,
  :global td.hidden-sm.hidden-lg {
    display: none !important;
  }
  :global .hidden-md.hidden-lg,
  :global tr.hidden-md.hidden-lg,
  :global th.hidden-md.hidden-lg,
  :global td.hidden-md.hidden-lg {
    display: none !important;
  }
  :global .hidden-lg,
  :global tr.hidden-lg,
  :global th.hidden-lg,
  :global td.hidden-lg {
    display: none !important;
  }
}
:global .visible-print,
:global tr.visible-print,
:global th.visible-print,
:global td.visible-print {
  display: none !important;
}
@media print {
  :global .visible-print {
    display: inherit !important;
  }
  :global tr.visible-print {
    display: table-row !important;
  }
  :global th.visible-print,
  :global td.visible-print {
    display: table-cell !important;
  }
  :global .hidden-print,
  :global tr.hidden-print,
  :global th.hidden-print,
  :global td.hidden-print {
    display: none !important;
  }
}

:global {
  /* MODAL POPUP STYLES: */
}
:global #dibs-popup-frame.dibs-popup {
  max-width: 976px;
  max-height: 90%;
  background-color: #fff;
  padding: 10px;
}
:global .dibs-popup .form-horizontal {
  margin: 0px;
}
:global .dibs-popup .form-horizontal .message img {
  margin-right: 20px;
}
:global .dibs-popup .form-horizontal .message {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 600;
  padding: 0.5em;
}
:global .dibs-popup .form-group {
  margin-top: 10px;
}
:global .dibs-popup .form-horizontal .modal-footer .controls {
  text-align: right;
}
:global .dibs-popup .info-wrapper .form-group {
  text-align: right;
}
:global .modal-open {
  margin-right: 15px;
  overflow: hidden;
}
:global .modal-open .navbar .nav.pull-right {
  margin-right: 15px;
}

:global a {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  color: #559b5e;
}
:global a:hover {
  color: #222;
}
:global .navMenuIcon {
  fill: #fff;
}
:global #outer-row {
  padding-top: 85px;
}
:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6,
:global .h1,
:global .h2,
:global .h3,
:global .h4,
:global .h5,
:global .h6 {
  text-transform: none;
}