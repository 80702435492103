.wrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  padding: 2px 9px;
}
@media (max-width: 568px) {
  .wrapper {
    padding: 2px 5px;
  }
}
.wrapper.disabled {
  cursor: not-allowed;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #bbb;
}

.day {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.day:hover {
  transition: background-color 0.3s ease;
  background-color: #b8e7c8;
}
.day.selected {
  background-color: #559b5e;
  color: #fff;
}
.day.today {
  border: 1px solid #ddd;
}
.day.outside {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #bbb;
}