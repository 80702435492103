:local .stopModalScroll {
  overflow-y: hidden;
}

:local .spinnerWrapper {
  background: #fff6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
}

@media (width >= 769px) {
  :local .spinnerWrapper {
    min-height: 250px;
  }
}

@media (width <= 768px) {
  :local .spinnerWrapper {
    z-index: 10;
  }
}
