.question {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  white-space: pre-wrap;
  text-transform: uppercase;
}

.arrowIcon {
  height: 11px;
  width: 11px;
  margin-left: 6px;
  vertical-align: middle;
}