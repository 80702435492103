span.emptyBadge {
  min-height: 14px;
  min-width: 14px;
}
@media (min-width: 1121px) {
  span.emptyBadge {
    bottom: 9px;
    margin-left: 1px;
  }
}

.notificationBadge {
  display: inline-block;
  color: #fff;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
  background-color: #cc0000;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  padding: 3px 5px 2px;
  min-width: 20px;
}
@media (min-width: 1121px) {
  .notificationBadge {
    position: absolute;
    bottom: 6px;
    left: 13px;
  }
}