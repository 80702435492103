.icon {
  height: 20px;
  width: 20px;
  margin-right: 9px;
}
@media (max-width: 1120px) {
  .icon .svgIcon {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}
@media (min-width: 1121px) {
  .icon {
    margin: 0 0 9px 0;
    padding: 0;
    position: relative;
  }
}

.svgIcon {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 3px;
}