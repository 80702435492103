.flexSpacer {
  flex-grow: 100;
}

[data-navtype*="dealer"] {
  --selectedColor: #436b93;
  --selectedBorderColor: #436b93;
  --navItemColor: #888;
  --dropdownColor: #888;
  --hoverColor: #436b93;
  --itemWidth: 95px;
  --beforeContent: "";
}

[data-navtype*="internal"] {
  --selectedColor: #fff;
  --navItemColor: #fff;
  --dropdownColor: #222;
  --hoverColor: #559b5e;
  --itemWidth: auto;
  --itemDisplay: block;
}

@media (width <= 768px) {
  [data-navtype*="internal"] {
    --dropdownColor: #fff;
  }
}

.dropdownItem {
  text-transform: none;
  margin-bottom: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.dropdownItem:last-of-type {
  margin-bottom: 0;
}

.dropdownItem a {
  color: var(--dropdownColor);
}

.dropdownItem > :not(a) {
  color: #222;
}

.dropdownItem a:hover, .dropdownItem a:focus {
  color: var(--hoverColor);
}

.dropdownDivider {
  border-top: 1px solid #ddd;
  margin-top: 18px;
  margin-bottom: 18px;
}

.circlePlus {
  fill: #436b93;
  width: 20px;
  height: 20px;
  margin-right: 9px;
  transition: all .2s;
  overflow: visible !important;
}

@media (width >= 1121px) and (width <= 1339px) {
  .separator {
    margin-right: 0;
    padding-right: 0 !important;
  }

  .separator:after {
    content: "";
    border-right: thin solid #666;
    height: 20px;
    margin: 0 18px;
  }
}

@media (width >= 1121px) and (width <= 1339px) and (width <= 768px) {
  .separator:after {
    margin: 0 18px;
  }
}

@media (width >= 1340px) {
  .separator {
    margin-right: 0;
    padding-right: 0 !important;
  }

  .separator:after {
    content: "";
    border-right: thin solid #666;
    height: 20px;
    margin: 0 9px;
  }
}

@media (width >= 1340px) and (width <= 768px) {
  .separator:after {
    margin: 0 18px;
  }
}

.item {
  display: var(--itemDisplay, flex);
  white-space: nowrap;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 9px;
  position: relative;
}

@media (width <= 1120px) {
  .item.dealerNavItem {
    padding: 9px 0;
  }

  .item.dealerNavItem.selected .interactiveElement:before {
    content: var(--beforeContent, none);
    border-left: 5px solid var(--selectedBorderColor, transparent);
    height: 20px;
    margin-left: -9px;
    margin-right: 4px;
    display: block;
  }
}

@media (width >= 1121px) {
  .item.dealerNavItem {
    min-width: var(--itemWidth);
    letter-spacing: .8px;
    border-bottom: 5px solid #0000;
    padding-bottom: 4px;
    transition: border .2s ease-in-out;
  }

  .item.dealerNavItem.selected {
    border-color: var(--selectedBorderColor, transparent);
  }

  .item.dealerNavItem .interactiveElement.withIcon {
    flex-direction: column;
    align-items: center;
  }
}

.item.selected svg * {
  stroke: #436b93;
  stroke-width: 7px;
  transition: all .3s;
}

.item.selected .interactiveElement {
  color: var(--selectedColor);
}

.item:hover:not(.noHover) .navTooltip, .item.open .navTooltip {
  opacity: 1;
  visibility: visible;
}

.item.expandingListElement {
  justify-content: space-between;
  width: 100%;
  padding-bottom: 9px;
}

.item.expandingListElement .interactiveElement {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.divider {
  background-color: #ddd !important;
  height: 1px !important;
  margin: 9px 0 !important;
  padding: 0 !important;
}

.interactiveElement {
  cursor: pointer;
  color: var(--navItemColor);
  text-transform: uppercase;
  letter-spacing: 1px;
  background: none;
  border: none;
  justify-content: space-between;
  align-items: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  display: flex;
  padding: 0 !important;
}

.interactiveElement:focus, .interactiveElement:hover {
  color: var(--hoverColor);
}

.interactiveElement.withIcon {
  flex-grow: 0;
}

.userMenu .interactiveElement.withIcon {
  flex-direction: row;
  flex-grow: 1;
}

.userMenu .interactiveElement.withIcon:hover {
  color: var(--navItemColor);
}

.badge {
  color: #fff;
  background-color: #436b93;
  border: 1px solid #436b93;
  border-radius: 10px;
  margin-left: 9px;
  padding: 2px 5px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
}

.coachmarkTooltip {
  text-transform: none;
  white-space: normal;
}

.coachmarkTooltip .coachmarkContent {
  color: #666;
}

.newBadge {
  color: #436b93;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.appStoreIcon {
  color: initial;
}
