.flexSpacer {
  flex-grow: 100;
}

[data-navtype*=dealer] {
  --logoFill: #222;
  --borderTopColor: #436b93;
  --navBackgroundColor: #fff;
  --outlineColor: #204664;
  --brandTextMinHeight: 18px;
  --focusDecoration: underline;
  --navHeadingWidth: 100%;
}

[data-navtype*=internal] {
  --navBackgroundColor: #222;
  --borderTopColor: #559b5e;
  --logoFill: #fff;
  --outlineColor: #2a7140;
  --navHeadingWidth: auto;
}

[data-navtype] {
  background-color: var(--navBackgroundColor);
  border-top: solid 3px var(--borderTopColor);
  box-shadow: 1px 2px 8px 0 rgba(34, 34, 34, 0.25);
  z-index: 980;
  top: 0;
  min-height: 68px;
  position: fixed;
  right: 0;
  left: 0;
  display: flex;
}
[data-navtype] *:focus {
  outline: var(--outlineColor) auto 3px;
}
@-moz-document url-prefix() {
  [data-navtype] a:focus {
    outline: none;
    text-decoration: var(--focusDecoration, none);
  }
  [data-navtype] button:focus {
    outline-style: dotted;
    outline-width: thin;
  }
}
@media (max-width: 1120px) {
  [data-navtype].dealerNav {
    flex-direction: column;
  }
  [data-navtype].dealerNav .navHeading {
    padding-right: 36px;
  }
}
@media (min-width: 1121px) {
  [data-navtype].dealerNav {
    padding-right: 36px;
    align-items: center;
  }
}
@media (min-width: 769px) {
  [data-navtype].internalNav {
    padding-right: 36px;
    align-items: center;
  }
}
@media (max-width: 768px) {
  [data-navtype].internalNav {
    flex-direction: column;
  }
  [data-navtype].internalNav .navHeading {
    padding-right: 36px;
  }
}
[data-navtype].isInSideBarNavTest {
  border: 0;
  border-bottom: 1px solid #ddd;
  padding-left: 280px;
  min-height: 60px;
  box-shadow: none;
}

.navHeading {
  display: flex;
}
@media (max-width: 1120px) {
  .navHeading {
    width: var(--navHeadingWidth);
  }
}

.logo {
  fill: var(--logoFill, #222);
  width: 80px;
}

.brand {
  min-height: 65px;
  min-width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brandText {
  min-height: var(--brandTextMinHeight, none);
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: #222;
}