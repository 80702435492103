:local .badge {
  color: #fff;
  background-color: #559b5e;
  border-radius: 9.5px;
  min-width: 19px;
  height: 19px;
  display: inline-block;
}

:local .badgeMedium {
  border-radius: 14.5px;
  min-width: 29px;
  height: 29px;
}

:local .badgeNeutral {
  color: #000;
  background-color: #f3f3f3;
}

:local .badgeAlert {
  color: #fff;
  background-color: #c00;
}

:local .badgeWarning {
  background-color: #f07f04;
}

:local .badgePill {
  color: #fff;
  background-color: #559b5e;
}

:local .badgeInteractive {
  cursor: default;
}

:local .badgeWhite {
  color: #222;
  background-color: #fff;
}

:local .badgeBuyerTertiary {
  background-color: #f6f3eb;
}

:local .badgeDealerTertiary {
  color: #222;
  background-color: #dee3eb;
}

:local .badgeMoonstone {
  color: #000;
  background-color: #f3f3f3;
}

:local .badgeCyan200 {
  color: #193148;
  background-color: #c6e6f1;
}

:local .badgeYellow200 {
  color: #5d4a20;
  background-color: #ffdf8b;
}

:local .badgeGreen200 {
  color: #0e3c1e;
  background-color: #ceecc1;
}

:local .badgeRed200 {
  color: #770303;
  background-color: #fecaca;
}

:local .badgeAlertSecondary {
  color: #fff;
  background-color: #950808;
}

:local .badgeInner {
  box-sizing: border-box;
  white-space: nowrap;
  letter-spacing: .5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2px 5px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  display: flex;
  transform: translateY(-1px);
}

:local .badgeInnerPill {
  padding: 2px 9px 0;
}

:local .badgeInnerSmallHeavy {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

:local .badgeInnerSmallRegular {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

:local .badgeInnerMediumRegular {
  padding-top: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

:local .badgeInnerMediumHeavy {
  padding-top: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

:local .badgeInnerCapitalize {
  text-transform: none;
}

:local .badgeInnerUppercase {
  text-transform: none;
}
