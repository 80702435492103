.header {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: #436b93;
  position: relative;
  top: 27px;
  text-align: center;
}

.imageContainer {
  background-color: #f3f3f3;
  padding: 18px 0;
  margin-top: 27px;
  margin-bottom: 36px;
  margin-left: -36px;
  width: 540px;
  text-align: center;
}

.image {
  width: 334px;
}