.maskIcon {
  height: 24px;
}

.arrowUpIcon {
  transform: translateY(-2px);
}

.tooltip {
  width: 217px;
}
