.list {
  list-style: none;
  position: relative;
  margin: 0;
  width: 100%;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  display: block;
  padding: 0 36px 18px;
  transition: opacity 0.1s ease;
}

.container {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  margin: 0;
}