[data-navtype*="dealer"] {
  --navWrap: nowrap;
}

[data-navtype*="internal"] {
  --navWrap: wrap;
}

.expandingMenu {
  overscroll-behavior: contain;
  border-top: thin solid #f3f3f3;
  width: 100%;
  min-height: calc(100dvh - 61px);
  max-height: calc(100dvh - 61px);
  overflow-y: scroll;
}

.expandingMenu .navbarItem {
  justify-content: space-between;
}

.navBar {
  flex-wrap: var(--navWrap);
  text-transform: uppercase;
  letter-spacing: 1px;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  list-style: none;
  display: flex;
  position: relative;
}

@media (width <= 768px) {
  .navBar {
    display: none;
  }
}

.navBar .userMenu {
  width: auto;
  min-width: unset;
  padding-left: 0;
}

.presenceOptIn {
  position: relative;
}

.userMenuContainer {
  display: flex;
  position: relative;
}
