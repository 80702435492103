:global .nav-pills > .active > a {
  color: #fff;
  background-color: #436b93;
}

:global .nav-pills > .active > a:hover {
  color: #fff;
  background-color: #436b93;
}

:global .nav-pills > .active > a:focus {
  color: #fff;
  background-color: #436b93;
}

:global .nav.nav-tabs > li > a {
  line-height: 22px;
}

:global .daterangepicker td.active {
  color: #fff;
  background-color: #436b93;
  background-image: none;
}

:global .daterangepicker td.active:hover {
  color: #fff;
  background-color: #436b93;
  background-image: none;
}

:is(:global .daterangepicker .calendar th, :global .daterangepicker .calendar td) {
  font-family: inherit;
}

:is(:global .table-striped > tbody > tr:nth-child(odd) > td, :global .table-striped > tbody > tr:nth-child(odd) > th) {
  background-color: #fbfbfb;
}

:global .l-with-fixed-top {
  margin-top: 74px;
}

@media (width >= 1121px) {
  :global .dibs-sidenav {
    z-index: 1 !important;
  }

  :global .dibs-sidenav.hide-nav {
    display: none;
  }

  :global div.container-fluid-sidenav {
    margin: 0;
    padding: 0;
  }
}

:is(:global #footer, :global #footer-sidenav) {
  clear: both;
}

:global .form-currency-select-view {
  width: 45px;
  margin-right: 10px;
}

:global .form-currency-amount-view {
  width: 150px;
}

:global .form-divider {
  border-bottom: 1px solid #ddd;
  margin-top: -5px;
}

:is(:global html, :global body) {
  height: 100%;
}

:is(:global #outer-row, :global #outer-row-sidenav, :global #outer-row-sidenav-hide) {
  padding-top: 85px;
}

@media (width >= 1121px) {
  :is(:global #outer-row-sidenav, :global #outer-row-sidenav-hide) {
    background-color: #f3f3f3;
    margin: 60px 0 0 253px;
    padding-top: 18px;
  }

  :global #outer-row-sidenav-hide {
    margin: 0;
    padding: 0;
  }
}

:global .no-background {
  background-color: unset !important;
}

:is(:global .hide-header #outer-row, :global .hide-header #outer-row-sidenav, :global .hide-header #outer-row-sidenav-hide) {
  padding-top: 0;
}

:global #wrapper {
  height: 100%;
  min-height: 100%;
  margin: 0 auto -30px;
  height: auto !important;
}

:is(:global #footer, :global #footer-sidenav, :global #push) {
  height: 30px;
}

:is(:global #footer, :global #footer-sidenav) {
  background-color: #fbfbfb;
  font-size: 11px;
  line-height: 30px;
}

@media (width >= 1121px) {
  :global #footer-sidenav {
    padding-left: 253px;
  }
}

:global .menu-wrapper .panel-heading {
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
}

:global .menu-wrapper .panel-heading i {
  float: right;
}

:global .menu-wrapper .panel-heading:hover {
  background-color: #f3f3f3;
}

:global .underline {
  text-decoration: underline;
}

:global .nav > li > a.user-link {
  text-transform: none;
}

:global .nav > li > a.user-link .bunsen-icon-mask {
  font-size: 10px;
}

:global .navbar-brand img {
  height: 23px;
  margin-top: -5px;
}

:global #wrapper {
  margin-top: 0;
}

:global #wrapper .hidden {
  display: none;
}

@media (width >= 1025px) {
  :global #wrapper > .row {
    margin-top: 70px;
  }
}

:global .no-min-height {
  min-height: 0 !important;
}

:global body .pointer {
  cursor: pointer;
}

:global body .top-align {
  vertical-align: top;
}

:global body .icon-xlarge {
  font-size: 1.7em;
}

:global body .valign-with-inputs {
  line-height: 29px;
}

:global body .full-width {
  width: 100%;
}

:global body .small-line-height {
  line-height: 16px;
}

:global body .min-line-height {
  line-height: 0;
}

:global body .text-wrap {
  white-space: normal;
}

:global body .no-padding {
  padding: 0;
}

@media (width <= 768px) {
  :global body .fluid-not-responsive [class*="span"] {
    float: left;
    min-height: 0;
    margin-left: 1.76243%;
  }

  :global .row [class*="tab-span"] {
    float: left;
    margin-left: 1.76243%;
  }

  :global .row .tab-col-md-12 {
    margin-left: 0;
  }

  :global .tab-hide {
    display: none;
  }

  :global .tab-full-width {
    width: 100% !important;
  }

  :global .tab-no-margin {
    margin: 0 !important;
  }

  :global .tab-no-side-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  :global .tab-no-padd {
    padding: 0 !important;
  }

  :global .tab-no-side-padd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

:global div.loader {
  height: 30px;
  display: none;
}

:global .well-1 {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #2222220d;
}

:global .hidden {
  display: none;
}

:global .inline-block {
  display: inline-block;
}

:global .inline {
  display: inline;
}

:global .rounded {
  border-radius: 4px;
}

:global .shadow {
  box-shadow: 0 0 5px 1px #2222221a;
}

:global .shadow-small {
  box-shadow: 0 0 2px #2222221a;
}

:global .hi-light {
  box-shadow: 0 0 4px #436b93;
}

:global .fake-input {
  border: 1px solid #ddd;
  border-radius: 3px;
  min-width: 50px;
  box-shadow: inset 1px 1px 2px #2222221a;
}

:global .glowing {
  border: 1px solid #d8ecf5;
  box-shadow: 0 0 5px #436b9380;
}

:global .fake-input.glowing {
  border: 1px solid #d8ecf5;
  box-shadow: 0 0 5px #436b9380;
}

:global .fake-input.disabled {
  color: #888;
  background-color: #f3f3f3;
  border: 1px solid #bbb;
  box-shadow: inset 1px 1px 2px #2222221a;
}

:global .clear {
  clear: both;
  min-width: 100%;
}

:global .mobile-show {
  display: none;
}

@media (width <= 568px) {
  :global .mobile-show {
    display: block;
  }

  :global .mobile-hide {
    display: none;
  }
}

:global .bg-aqua {
  background-color: #0f8d88;
}

:global .bg-grey {
  background-color: #888;
}

:global input.invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global select.invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global textarea.invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global span.invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global .chzn-container.invalid a {
  color: #c00;
  border: 1px solid #c00;
}

:global input.valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global select.valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global textarea.valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global span.valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global .chzn-container.valid a {
  color: #66a559;
  border: 1px solid #66a559;
}

:is(:global .radio input.valid, :global .radio input.invalid) {
  border: 0;
}

:global .price {
  display: block;
}

:global .mock-table .tbody {
  width: 100%;
  display: table;
}

:global .mock-table .row {
  display: table-row;
}

:global .mock-table .row:nth-child(2n) {
  background-color: #f3f3f3;
}

:global .mock-table .field {
  vertical-align: top;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 10px;
  display: table-cell;
}

:global .mock-table .field:last-child {
  border-right: none;
}

:global .mock-table .headings .field {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

:global .disable-overlay {
  z-index: 10;
  background-color: #fffc;
  width: 100%;
  height: 100%;
  position: absolute;
}

:global .lt-ie9 .disable-overlay {
  filter: alpha(opacity= 70);
  background-color: #fff;
}

:global .disabled {
  opacity: .75;
}

:global .clear-b {
  clear: both;
}

:global strong.big {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 600;
}

:global .credit {
  text-shadow: 0 0 1px #fff;
  margin-bottom: 0;
  padding-left: 20px;
}

:global .loading {
  text-align: center;
  clear: both;
  margin: 0 auto;
  padding: 15%;
}

@media print {
  :global #dibs-nav {
    display: none;
  }

  :global #footer {
    display: none;
  }

  :global #footer-sidenav {
    display: none;
  }

  :global .omt-title {
    display: none;
  }

  :global #ki_container {
    display: none;
  }

  :global #node-diagnostics {
    display: none;
  }

  :global body {
    font-size: 12px;
  }

  @page {
  }

  :global #page {
    margin-top: -61px;
  }
}

a {
  text-decoration: none;
}
