.sideBar {
  width: 253px;
  height: 100vh;
  border-right: solid 1px #ddd;
}

.plusIcon {
  transform: translateY(8px);
}

.listingOptionIcon {
  transform: translateY(1px);
}