.container {
  text-align: center;
}

.header {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 9px;
  display: flex;
  justify-content: space-between;
  user-select: none;
}
@media (max-width: 568px) {
  .header {
    margin-bottom: 18px;
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 18px;
    font-weight: 600;
  }
}

.weekDaysContainer {
  display: flex;
}

.weekDay {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0;
  margin: 0 9px;
  width: 30px;
  user-select: none;
}
@media (max-width: 568px) {
  .weekDay {
    margin: 0 5px;
  }
}

.arrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrowWrapper .arrow {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: 0 18px;
}
@media (max-width: 568px) {
  .arrowWrapper .arrow {
    margin: 0 9px;
    width: 22px;
    height: 22px;
  }
}
.arrowWrapper .arrow.disabled {
  cursor: not-allowed;
  fill: #bbb;
}
.arrowWrapper .arrow.disabled:hover {
  fill: #bbb;
}
.arrowWrapper .arrow:hover {
  fill: #666;
}