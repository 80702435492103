.flexSpacer {
  flex-grow: 100;
}

[data-navtype*=dealer] {
  --selectedColor: #436b93;
  --selectedBorderColor: #436b93;
  --navItemColor: #888;
  --dropdownColor: #888;
  --hoverColor: #436b93;
  --itemWidth: 95px;
  --beforeContent: "";
}

[data-navtype*=internal] {
  --selectedColor: #fff;
  --navItemColor: #fff;
  --dropdownColor: #222;
  --hoverColor: #559b5e;
  --itemWidth: auto;
  --itemDisplay: block;
}
@media (max-width: 768px) {
  [data-navtype*=internal] {
    --dropdownColor: #fff;
  }
}

.dropdownItem {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  text-transform: none;
  margin-bottom: 9px;
}
.dropdownItem:last-of-type {
  margin-bottom: 0;
}
.dropdownItem a {
  color: var(--dropdownColor);
}
.dropdownItem > :not(a) {
  color: #222;
}
.dropdownItem a:hover,
.dropdownItem a:focus {
  color: var(--hoverColor);
}

.dropdownDivider {
  border-top: 1px solid #ddd;
  margin-bottom: 18px;
  margin-top: 18px;
}

.circlePlus {
  overflow: visible !important;
  height: 20px;
  width: 20px;
  margin-right: 9px;
  fill: #436b93;
  transition: all 0.2s ease;
}

@media (min-width: 1121px) and (max-width: 1339px) {
  .separator {
    margin-right: 0;
    padding-right: 0 !important;
  }
  .separator:after {
    content: "";
    height: 20px;
    margin: 0 18px;
    border-right: solid thin #666;
  }
}
@media (min-width: 1121px) and (max-width: 1339px) and (max-width: 768px) {
  .separator:after {
    margin: 0 18px;
  }
}
@media (min-width: 1340px) {
  .separator {
    margin-right: 0;
    padding-right: 0 !important;
  }
  .separator:after {
    content: "";
    height: 20px;
    margin: 0 9px;
    border-right: solid thin #666;
  }
}
@media (min-width: 1340px) and (max-width: 768px) {
  .separator:after {
    margin: 0 18px;
  }
}

.item {
  display: var(--itemDisplay, flex);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex: 0 1 auto;
  padding: 9px;
  position: relative;
}
@media (max-width: 1120px) {
  .item.dealerNavItem {
    padding: 9px 0;
  }
  .item.dealerNavItem.selected .interactiveElement:before {
    content: var(--beforeContent, none);
    height: 20px;
    display: block;
    margin-left: -9px;
    margin-right: 4px;
    border-left: 5px solid var(--selectedBorderColor, transparent);
  }
}
@media (min-width: 1121px) {
  .item.dealerNavItem {
    min-width: var(--itemWidth);
    padding-bottom: 4px;
    border-bottom: 5px solid transparent;
    letter-spacing: 0.8px;
    transition: border 0.2s ease-in-out;
  }
  .item.dealerNavItem.selected {
    border-color: var(--selectedBorderColor, transparent);
  }
  .item.dealerNavItem .interactiveElement.withIcon {
    flex-direction: column;
    align-items: center;
  }
}
.item.selected svg * {
  transition: all ease 0.3s;
  stroke: #436b93;
  stroke-width: 7px;
}
.item.selected .interactiveElement {
  color: var(--selectedColor);
}
.item:hover:not(.noHover) .navTooltip, .item.open .navTooltip {
  opacity: 1;
  visibility: visible;
}
.item.expandingListElement {
  width: 100%;
  justify-content: space-between;
  padding-bottom: 9px;
}
.item.expandingListElement .interactiveElement {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}

.divider {
  padding: 0 !important;
  background-color: #ddd !important;
  height: 1px !important;
  margin: 9px 0 !important;
}

.interactiveElement {
  cursor: pointer;
  color: var(--navItemColor);
  background: none;
  border: none;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}
.interactiveElement:focus, .interactiveElement:hover {
  color: var(--hoverColor);
}
.interactiveElement.withIcon {
  flex-grow: 0;
}

.userMenu .interactiveElement.withIcon {
  flex-grow: 1;
  flex-direction: row;
}
.userMenu .interactiveElement.withIcon:hover {
  color: var(--navItemColor);
}

.badge {
  color: #fff;
  background-color: #436b93;
  padding: 2px 5px 0;
  margin-left: 9px;
  border: 1px solid #436b93;
  border-radius: 10px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
}

.coachmarkTooltip {
  text-transform: none;
  white-space: normal;
}
.coachmarkTooltip .coachmarkContent {
  color: #666;
}

.newBadge {
  color: #436b93;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.appStoreIcon {
  color: initial;
}