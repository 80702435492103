.truncateCompanyName {
  margin-right: 9px;
  text-transform: none;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 130px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
@media (max-width: 1024px) {
  .truncateCompanyName {
    display: none;
  }
}

.iconContainer {
  all: initial;
  position: relative;
}

@media (max-width: 1340px) {
  .hidden {
    display: none;
  }
}