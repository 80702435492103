[data-navtype*=dealer] {
  --navWrap: nowrap;
}

[data-navtype*=internal] {
  --navWrap: wrap;
}

.expandingMenu {
  overscroll-behavior: contain;
  width: 100%;
  border-top: solid thin #f3f3f3;
  overflow-y: scroll;
  min-height: calc(100dvh - 61px);
  max-height: calc(100dvh - 61px);
}
.expandingMenu .navbarItem {
  justify-content: space-between;
}

.navBar {
  flex-wrap: var(--navWrap);
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  display: flex;
  height: 100%;
  align-items: center;
}
@media (max-width: 768px) {
  .navBar {
    display: none;
  }
}
.navBar .userMenu {
  width: auto;
  min-width: unset;
  padding-left: 0;
}

.presenceOptIn {
  position: relative;
}

.userMenuContainer {
  position: relative;
  display: flex;
}