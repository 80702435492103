span.emptyBadge {
  min-width: 14px;
  min-height: 14px;
}

@media (width >= 1121px) {
  span.emptyBadge {
    margin-left: 1px;
    bottom: 9px;
  }
}

.notificationBadge {
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #c00;
  border-radius: 10px;
  min-width: 20px;
  padding: 3px 5px 2px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
}

@media (width >= 1121px) {
  .notificationBadge {
    position: absolute;
    bottom: 6px;
    left: 13px;
  }
}
