.container {
  text-align: center;
}

.header {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  margin-bottom: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

@media (width <= 568px) {
  .header {
    margin-bottom: 18px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 18px;
    font-weight: 600;
  }
}

.weekDaysContainer {
  display: flex;
}

.weekDay {
  -webkit-user-select: none;
  user-select: none;
  width: 30px;
  margin: 0 9px;
  padding: 5px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

@media (width <= 568px) {
  .weekDay {
    margin: 0 5px;
  }
}

.arrowWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.arrowWrapper .arrow {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: 0 18px;
}

@media (width <= 568px) {
  .arrowWrapper .arrow {
    width: 22px;
    height: 22px;
    margin: 0 9px;
  }
}

.arrowWrapper .arrow.disabled {
  cursor: not-allowed;
  fill: #bbb;
}

.arrowWrapper .arrow.disabled:hover {
  fill: #bbb;
}

.arrowWrapper .arrow:hover {
  fill: #666;
}
