.flexSpacer {
  flex-grow: 100;
}

[data-navtype*="internal"] {
  --logoFill: #fff;
  --logoHover: #559b5e;
}

.notificationIndicator {
  fill: #c00;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  right: 0;
}

.responsiveMenuItem {
  align-items: center;
  display: flex;
}

.separatorOnMany:not(:last-of-type) {
  margin-right: 0;
  padding-right: 0 !important;
}

.separatorOnMany:not(:last-of-type):after {
  content: "";
  border-right: solid thin var(--logoFill);
  height: 20px;
  margin: 0 18px;
}

@media (width <= 768px) {
  .separatorOnMany:not(:last-of-type):after {
    margin: 0 18px;
  }
}

.userLabel, .masqueradeLabel {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.navMenuIcon {
  vertical-align: middle;
  height: 20px;
}

.directLoginIcon {
  vertical-align: middle;
  height: 25px;
}

.dibsLayoutUserIcon, .directLoginIcon, .navMenuIcon {
  fill: var(--logoFill, #222);
}

.userMenuToggle, .navMenuToggle {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 5px;
  position: relative;
}

.iconContainer {
  all: initial;
  position: relative;
}
